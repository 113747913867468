$(function(){
	if($('.fluid-carousel') && $('.fluid-carousel').length > 0) {
		$('.fluid-carousel.owl-carousel').owlCarousel({
			loop: false,
      autoplay: true,
      autoplayHoverPause: true,
      margin: 0,
      items: 1,
      nav: false,
      navText: [
        "<svg class='sorting-arrows__arrow sorting-arrows__arrow--down right-arrow'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>",
        "<svg class='sorting-arrows__arrow sorting-arrows__arrow--down left-arrow'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>"
      ]
    });
  }
});

