﻿import { analyticsEvent } from '../controllers/analytics-controller';
var loadRelatedArticles = function () {
    var articleNumber = $("#fur-read-articleNumber").val();
    var pageTitle = $("#fur-read-pageTitle").val();
    var pageType = $("#fur-read-pageType").val();
    if(articleNumber){
      $.ajax({
        type: "GET",
        url: "/api/informasearch/fetchrelatedarticle?articleNumber=" + articleNumber,
        success: function success(result) {
          var $iirisRelatedArticles = $(".iirisrelatedarticles");
          $iirisRelatedArticles.each(function () {
            var $iirisRelatedArticle = $(this);
            if ($iirisRelatedArticle.closest('.pop-out').length > 0) 
              $iirisRelatedArticle.addClass('in-sidebar');
      
            if (result != null && result.length > 0) {
              $iirisRelatedArticle.html('');
              if ($("#fur-read-max-item").length == 0) {
                return;
              }
              var furReadMaxItem = parseInt($("#fur-read-max-item").val());
              var html = '';
              if (!$iirisRelatedArticle.hasClass('in-sidebar')) {
                $iirisRelatedArticle.removeClass('inf-row');
                html = html + '<div class="inf-row owl-carousel">';
              }
              var i = result.length <= furReadMaxItem - 1 ? result.length - 1 : furReadMaxItem - 1;
              for (; i >= 0; i--) {
                var articleURL = result[i].url;
                var data = { 'event_name': 'related_content_click_through', 'iiris-ref': 'recommend', 'click_through_source': pageTitle, 'click_through_destination': result[i].title, 'source_page_type': pageType };
                html = html + '<div class="inf-col-fluid"><div class="content-box bdr-top featured-research-container"> <div class="asset_family_mod">';
                html = html + "<h5 class='omd-marg-b-1'>";
                html = html + "<a href='" + articleURL + "' class='click-utag'";
                html = html + "data-info='" + JSON.stringify(data) + "' > " + result[i].title + '</a></h5>';
                html = html + '<p class="body">' + result[i].synopsis + '</p>';
                html = html + '<p class="omd-label omg-marg-b-1">' + result[i].listableAuthorByLine + '</p>';
                html = html + '<p class="entitlement">' + result[i].entitledText + '</p>';
                html = html + ' </div> </div> </div>';
              }
              if (!$iirisRelatedArticle.hasClass('in-sidebar'))
                html = html + '</div>';
        
              $iirisRelatedArticle.html(html);
        
              $('.iirisrelatedarticles .asset_family_mod').each(function () {
                var $anchor = $(this).find(".click-utag");
                $anchor.css('pointer-events', 'none');
                
                $(this).off('click').on('click', function (e) {
                  var dataInfo = $anchor.data('info');
                  if ($anchor.hasClass("legacy_data_info_ignore")) {
                    //rely on the data properties instead of data-info
                    dataInfo = null;
                  }
                  if (!dataInfo) {
                    dataInfo = $anchor.data();
                  }
                  analyticsEvent($.extend(analytics_data, dataInfo));
                  window.location.href = $anchor.attr("href");
                });
              });
              $(".omdia-recommends-box-popout-button").show();
              furtherReadingCarouselInit();
            } else {
              $("#iiris-related-articles").hide();
              $('.omd-further-reading').parent().hide();
            }
          });
        },
        error: function error(a) {
          console.log('error calling function');
          $('.omd-further-reading').parent().hide();
        }
      });
    }
}

var furtherReadingCarouselInit = function () {
  var $owlCarousels = $(".omd-further-reading .owl-carousel"),
      owlItemCount = $owlCarousels.children().length,
      desktopItemCount = 3,
      tabletItemCount = 2,
      mobileItemCount = 1,
      desktopWidth = 1280,
      tabletWidth = 768,
      windowWidth = $(window).width(),
      isDesktop = windowWidth >= desktopWidth,
      isTablet = !isDesktop && windowWidth >= tabletWidth,
      isMobile = !isTablet;

  if (isDesktop && owlItemCount <= desktopItemCount) return;
  if (isTablet && owlItemCount <= tabletItemCount) return;
  if (isMobile && owlItemCount <= mobileItemCount) return;

  $owlCarousels.each(function () {
    var $owlCarousel = $(this);

    $owlCarousel.on("onInitAfter", function () {
      if (isDesktop) {
        $owlCarousel.find(".owl-next").addClass("disabled");
      } else {
        $owlCarousel.find(".owl-prev").addClass("disabled");
      }
    });

    $owlCarousel.on("onChangeState", function () {
      var owlCarouselData = $(this).data("owlCarousel");

      if (!owlCarouselData) return;

      var { current, min, max } = owlCarouselData.pos;
      var { $navNext, $navPrev } = owlCarouselData.dom;

      switch (current) {
        case min:
          if (isDesktop) {
            $navNext.addClass("disabled");
            $navPrev.removeClass("disabled");
          } else {
            $navNext.removeClass("disabled");
            $navPrev.addClass("disabled");
          }
          break;
        case max:
          if (isDesktop) {
            $navNext.removeClass("disabled");
            $navPrev.addClass("disabled");
          } else {
            $navNext.addClass("disabled");
            $navPrev.removeClass("disabled");
          }
      }
    });

    $owlCarousel.owlCarousel({
      responsiveClass: true,
      loop: false,
      items: desktopItemCount,
      slideBy: desktopItemCount,
      nav: true,
      dots: true,
      callbacks: true,
      navText: [
        "<svg><use xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>",
        "<svg><use xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>",
      ],
      responsive: {
        0: {
          items: mobileItemCount,
          slideBy: 1,
        },
        [tabletWidth]: {
          items: tabletItemCount,
          slideBy: 1,
        },
        [desktopWidth]: {
          items: desktopItemCount,
          slideBy: desktopItemCount,
        },
      },
    });
  });
};

window.addEventListener("DOMContentLoaded", function () {
  loadRelatedArticles();
  furtherReadingCarouselInit();
});
