(function () {
  var $rootContainer = $('.commissioned-research'),
      $contentTypeSel,
      $contentCat,
      contentCatStr,
      contentCatArr,
      selMutationObserv,
      $searchResults,
      $topicSections,
      $topicNav,
      $topicNavItems,
      init = function () {
        // page check
        if (!$rootContainer || $rootContainer.length === 0) return;
        
        // set vars
        $contentTypeSel = $rootContainer.find('#contentType');
        $contentCat = $rootContainer.find('#ContentCategories');
        if ($contentCat.length > 0) {
          contentCatStr = $contentCat.val();
          contentCatArr = contentCatStr.length > 0 ? contentCatStr.split(', ') : [];
        } else {
          contentCatArr = [];
        }
        $searchResults = $rootContainer.find('.search-result');
        $topicSections = $rootContainer.find('.topic-section');
        $topicNav = $rootContainer.find('.nav');

        // init funcs
        initSelect();
        // detect selectivity init via watching for removal of original select
        selMutationObserv = new MutationObserver(function (e) {
          if (e[0].removedNodes) {
            initSelectivity();
            // stop watching so this only runs once
            selMutationObserv.disconnect();
          }
        });
        
        // set the mutation observer to watch parent of select
        selMutationObserv.observe($contentTypeSel.parent()[0], { childList: true });

        initJumptoTopicsNav();
        emptyTopicCheck();
      },
      onSelect = function (id) {
        // when non-all item selected, hide all then show the results that match the category id
        if (id === 'all') {
          // show all
          $searchResults.show();
        } else {
          // hide all, then show the selected
          $searchResults.hide();
          $searchResults.filter('[category="' + id + '"]').show();
        }
        // always do an empty check to re-show hidden items for all
        emptyTopicCheck();
      },
      initSelect = function() {
        // add options from hidden field ContentCategories, to original select
        contentCatArr.forEach(function(contentCat) {
          $contentTypeSel.append('<option value="' + contentCat + '">' + contentCat + '</option>');
        });
        // add event to native select in case of no selectivity
        $contentTypeSel.on('change', function (e) { onSelect(e.target.value) });
      },
      initSelectivity = function () {
        // reselect via id to select selectivity replacement of select field
        $contentTypeSel = $rootContainer.find('#contentType')
        // selectivity specific "change" event handler
        $contentTypeSel.on('selectivity-selected', function(e) { onSelect(e.id) });
      },
      initJumptoTopicsNav = function() {
        // get page title for tracking
        var $pageTitle = $('h1'),
            pageTitle = $pageTitle.length > 0 ? $('h1').text() : '',
            $topicSection,
            topicTitle,
            topicId;
        
        $topicSections.each(function() {
          $topicSection = $(this);
          topicTitle = $topicSection.attr('value');
          // create id from topic title and remove id incompatible characters
          topicId = topicTitle.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\ \{\}\[\]\\\/]/gi, '');

          // add id to topic section
          $topicSection.attr('id', topicId);
          // create anchor with href="#id-of-topic" and content = topicTitle
          $topicNav.append('<p><a id="' + topicId + '-anchor" class="click-utag" data-info="{"event_name":"anchor_clicks","click_through_source":"' + topicTitle + '","click_destination":"' + topicTitle + '","source_page_type":"' + pageTitle + '"}" href="#' + topicId + '">' + topicTitle + '</a></p>');
        });
      },
      emptyTopicCheck = function() {
        var $topicNavItem,
            $topicNavItemWrapper,
            topicSectionId,
            $topicSection,
            $topicSectionResults;
        
        // set var if not set for repeated use
        if(!$topicNavItems)
          $topicNavItems = $topicNav.find('a');
        
        $topicNavItems.each(function() {
          $topicNavItem = $(this);
          $topicNavItemWrapper = $topicNavItem.closest('p');
          // get id for topic section from anchor's id
          topicSectionId = $topicNavItem.attr('id').split('-anchor')[0];
          // select the associated topic section using id
          $topicSection = $topicSections.filter('#' + topicSectionId);
          $topicSectionResults = $topicSection.children('.search-result');
          // hide by default
          $topicNavItemWrapper.hide();
          $topicSection.hide();
          // if atleast one child is visible then show and abandon loop
          $topicSectionResults.each(function () {
            if ($(this).css('display') !== 'none') {
              $topicNavItemWrapper.show();
              $topicSection.show();
              return false;
            }
          });          
        });
      }

  init();
})();