(function () {
  var $revealers,
      $revealer,
      $revealItemsContainer,
      $revealItems,
      revealItemsLength,
      $revealItem,
      initialShowCount,
      revealShowCount,
      revealShowTrueCount,
      dontSetFromHeight,
      dontSetToHeight,
      $revealerTrigger,
      setupRevealers = function() {
        $revealers = $('[revealer-component]')
        $revealers.each(function() {
          $revealer = $(this);

          if ($revealer.data('isSetup')) return true;

          $revealerTrigger = $revealer.find('[revealer-trigger]');
          $revealItemsContainer = $revealer.find($revealer.data('container-selector'));
          $revealItems = $revealItemsContainer.children();
          revealItemsLength = $revealItems.length;
          initialShowCount = parseInt($revealer.data('expand-from') || 0);
          
          // stop here if revealer not needed
          if (revealItemsLength <= initialShowCount) {
            $revealerTrigger.addClass('disabled');
            // remove current element and stop here
            $revealers = $revealers.not($revealer);
            return true;
          } else {
            // ensure revealer button has required css props
            $revealerTrigger.css('cursor', 'pointer').removeClass('disabled');
          }

            // ensure parent has required css props
            $revealItemsContainer.css('transition', 'height 0.5s');
            $revealItemsContainer.css('overflow', 'hidden');

            // set position so we get correct offset
            if ($revealItemsContainer.css('position') === 'static')
              $revealItemsContainer.css('position', 'relative');
              
            // add click event to revealer button
            $revealerTrigger.click(function(e) {
              e.preventDefault();
              e.stopImmediatePropagation();
              $revealerTrigger = $(this);

              // stop here when disabled
              if ($revealerTrigger.hasClass('disabled')) return;

              // reset elements relative to this trigger
              $revealer = $revealerTrigger.data('revealer');
              $revealItemsContainer = $revealerTrigger.data('revealItemsContainer');

              // toggle class and set height according to state
              $revealerTrigger.toggleClass('revealed');
              if ($revealerTrigger.hasClass('revealed')) {
                $revealItemsContainer.css('height', $revealer.data('expand-to-height'));
              } else {
                $revealItemsContainer.css('height', $revealer.data('expand-from-height'));
              }
            });

            $revealer.data('isSetup', true);
        });
      };
    
  setupRevealers();

  window.renderRevealer = function() {

    setupRevealers();

    $revealers.each(function() {
      $revealer = $(this);

      if ($revealer.data('isRendered')) return true;

      // set vars per revealer
      $revealItemsContainer = $revealer.find($revealer.data('container-selector'));
      $revealItems = $revealItemsContainer.children();
      revealItemsLength = $revealItems.length;
      initialShowCount = parseInt($revealer.data('expand-from') || 0);
      revealShowCount = $revealer.data('expand-to') ? parseInt($revealer.data('expand-to')) : Infinity;
      revealShowTrueCount = revealShowCount > revealItemsLength ? revealItemsLength : revealShowCount;
      dontSetFromHeight = false;
      dontSetToHeight = false;
      $revealerTrigger = $revealer.find('[revealer-trigger]');

      // ensure parent has required css props
      $revealItemsContainer.css('height', $revealItemsContainer.height());

      if (initialShowCount === 0) {
        $revealer.data('expand-from-height', 0);
        dontSetFromHeight = true;
      }

      if (!isFinite(revealShowTrueCount)) {
        $revealer.data('expand-to-height', $revealItemsContainer.height());
        dontSetToHeight = true;
      }

      if (!dontSetFromHeight || !dontSetToHeight) {
        // set positions for revealer click event
        $revealItems.each(function(revealItemInd) {
          $revealItem = $(this);
          if (!dontSetFromHeight && revealItemInd === (initialShowCount - 1)) {
            $revealer.data('expand-from-height', $revealItem.position().top + $revealItem.height());
          }
          if (!dontSetToHeight && revealItemInd === (revealShowTrueCount - 1)) {
            $revealer.data('expand-to-height', $revealItem.position().top + $revealItem.height());
          }
        });
      }

      // set initial view
      $revealItemsContainer.css('height', $revealer.data('expand-from-height'));

      // set element refernces to button data for refernce within handler
      $revealerTrigger.data('revealer', $revealer);
      $revealerTrigger.data('revealItemsContainer', $revealItemsContainer);

      $revealer.data('isRendered', true);
    });
  }
      
  $(window).on('load', function() {
    window.renderRevealer();
  });
})();