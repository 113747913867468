/* global angular */
function lightboxModalController() {

    this.closeLightboxModal = function() {
        $('body').removeClass('lightboxed');
        $('.lightbox-modal__backdrop').remove();
        $('.lightbox-modal').hide();
    };

    var closeLightboxModal = this.closeLightboxModal;

    this.showLightbox = function(lightbox) {
        // Freeze the page and add the dark overlay
        $('body')
            .addClass('lightboxed')
            .append('<div class="lightbox-modal__backdrop"></div>');

        // Find the specific modal for this trigger, and the associated form
        var targetModal = $(lightbox).data('lightbox-modal');
        if($('#empty-form').length) {
            var successForm = ($('#empty-form'));
        } else {
            var successForm =$(lightbox).closest('.' + $(lightbox).data('lightbox-modal-success-target'));
        }
        

        // Show the modal, add an on-click listener for the "success" button
        $('.' + targetModal)
            .show()
            .find('.js-lightbox-modal-submit')
            // .one, not .on, to prevent stacking event listners
            .one('click', function(e) {
                if ($(lightbox).data('alertid') != null && $(lightbox).data('title') != null) {
                        $('#empty-form input[name ="Id"]').val($(lightbox).data('alertid'));
                        $('#empty-form input[name ="Title"]').val($(lightbox).data('title'));
                        $('#empty-form input[name ="rowId"]').val($(lightbox).data('index'));
                }

                successForm.find('button[type=submit]').click();
                closeLightboxModal();
            });

        return false;
    };

    this.showLightboxnone = function (lightbox) {
        // Freeze the page and add the dark overlay
        $('body').addClass('lightboxed').append('<div class="lightbox-modal__backdrop"></div>');

        // Find the specific modal for this trigger, and the associated form
        var targetModal = $(lightbox).data('lightbox-modal');
        
        var targetModalNone = $(lightbox).data('lightbox-modal-none');
        
        if ($('#empty-form-none').length) {
            var successForm = $('#empty-form-none');
        } else {
            var successForm = $(lightbox).closest('.' + $(lightbox).data('lightbox-modal-success-target-none'));
        }
       

        // Show the modal, add an on-click listener for the "success" button
        $('.' + targetModalNone).show().find('.js-lightbox-modal-submit')
            // .one, not .on, to prevent stacking event listners
            .one('click', function (e) {
              
                if ($(lightbox).data('alertid') != null && $(lightbox).data('title') != null) {
                    $('#empty-form-none input[name ="Id"]').val($(lightbox).data('alertid'));
                    $('#empty-form-none input[name ="Title"]').val($(lightbox).data('title'));
                    $('#empty-form-none input[name ="rowId"]').val($(lightbox).data('index'));
                   
                }

                successForm.find('button[type=submit]').click();
                closeLightboxModal();
            });

        return false;
    };

    var showLightbox = this.showLightbox;
    var showLightboxnone = this.showLightboxnone;

    this.buildLightboxes = function() {
        $('.js-lightbox-modal-trigger').on('click', function(e) {

            if (e.target !== this) {
                this.click();
                return;
            }

            showLightbox(e.target);

            // Don't submit any forms for real.
            return false;
        });
        $('.js-lightbox-modal-trigger-none').on('click', function (e) {

            if (e.target !== this) {
                this.click();
                return;
            }

            showLightboxnone(e.target);

            // Don't submit any forms for real.
            return false;
        });
    };

    // When the Dismiss button is clicked...
    $('.js-close-lightbox-modal').on('click', function(e) {
        closeLightboxModal();
    });

    this.buildLightboxes();

	this.clearLightboxes = function() {
		$('.js-lightbox-modal-trigger').off();
	};

}

export default lightboxModalController;
