var articleSidebarAd,
    articleSidebarAdParent,
    lastActionFlagsBar,
    stickyFloor,
    sidebarIsTaller,
    rightRail;
$(window).on('load', function () {
    if ($("img.limelightvideo-thumbnail-img").length > 0) {
        $("img.limelightvideo-thumbnail-img").forEach(v => {
            if ($(v).attr('data-mediaid') != null) {
                var mediaId = v.dataset.mediaid;
                var playerWidth = v.dataset.width;
                var playerHeight = v.dataset.height;
                var playerId = v.dataset.playerid;
                var videoPlayer = '<div id="limelight_player_{playerid}">&nbsp;</div><script>LimelightPlayerUtil.embed({"height": {height}, "width": {width}, "mediaId": "{mediaid}", "playerId": "limelight_player_{playerid}", "playerForm": "LVPPlayer" });</script>';
                videoPlayer = videoPlayer.replace(/{playerid}/g, playerId);
                videoPlayer = videoPlayer.replace(/{mediaid}/g, mediaId);
                videoPlayer = videoPlayer.replace(/{width}/g, playerWidth);
                videoPlayer = videoPlayer.replace(/{height}/g, playerHeight);
                $(v).parent().append(videoPlayer);
                $(v).remove();
            }
        });
    }

    if ($(".body-master-iframe").length > 0) {
        $(".body-master-iframe").forEach(function (v) {
            v.remove();
        });
    }
    
    if($('.article-right-rail section:last-child').find(".article-preview__ad").hasClass("hidden")) {
        articleSidebarAdParent = $('.article-right-rail section:last-child').prev();
    } else {
        articleSidebarAdParent = $('.article-right-rail section:last-child');
    }
    articleSidebarAd = articleSidebarAdParent.find('.advertising');
    lastActionFlagsBar = $('.action-flags-bar:last-of-type');
    setTimeout(function(){
		sidebarIsTaller = $('.article-right-rail').height() > $('.article-left-rail').height();
	}, 1000);
	if($('.article-right-rail').length > 0) {
        rightRail = $('.article-right-rail').offset().left;
    }
});
$(window).on('scroll', function () {
    if (articleSidebarAdParent && articleSidebarAdParent.length && sidebarIsTaller === false) {
        // pageYOffset instead of scrollY for IE / pre-Edge compatibility
        stickyFloor = lastActionFlagsBar.offset().top - window.pageYOffset - articleSidebarAd.height();
        if (articleSidebarAdParent.offset().top - window.pageYOffset <= 16) {
            articleSidebarAdParent.addClass('advertising--sticky');
			// if($('.article-right-rail').length > 0) {
   //              articleSidebarAdParent.find('.advertising').css('left', rightRail + 'px');
   //          }
        } else {
            articleSidebarAdParent.removeClass('advertising--sticky');
        }
        if (stickyFloor <= 40) {
            articleSidebarAd.css('top', (stickyFloor - 40) + 'px');
        } else {
            articleSidebarAd.css('top', '');
        }
        if ($("body").hasClass("theme-chemistanddruggist")) {
			if (stickyFloor <= 200) {
                articleSidebarAd.css('top', stickyFloor - 200 + 'px');
			} else {
				articleSidebarAd.css('top', '');
			}
		}
    }
});