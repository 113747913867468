$(function() {
    var $body = $('body'), 
        $theamOmdia = $body.hasClass('theme-omdia');
        
        $(".download-dropdown").on('change', function (event) {
            if($theamOmdia) {
                var value = $(this).find('select').val();
                window.open(value, 'newwindow');
            }
        });
});