$(function () {	
	var solutionPagesize = $('#solution-pagesize').val(),
	    solutionDataTotalCount = $('#solution-count').val(),
		solutionFilterVal = $("#solutionFilter .custom-select-list li.active").attr("data-list-value"),
		solutionDropdown = $("#solution-dropdown").val(),
		hashParamObj, firstPage = 1, 
		solutionLIstView = $('#solution-showlistview').val();

	var parseHashParms = function parseHashParms(str) {
		if(str.indexOf("?") != -1) {
			str = str.split("?")[1];
			var pieces = str.split("&"),
				data = {},
				i,
				parts;
			// process each query pair
			for (i = 0; i < pieces.length; i++) {
				parts = pieces[i].split("=");
				if (parts.length < 2) {
					parts.push("");
				}
				data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
			}
		}
		return data;
	};
	
	var updateHashParam = function updateHashParam(key, value) {
		var hashValue = window.location.hash,
			paramObj = {};

		if (hashValue == "") {
			hashValue += key + "=" + value;
		} else {
			paramObj = parseHashParms(hashValue);
			if (value != "") {
				paramObj[key] = value;
			} else {
				delete paramObj[key];
			}
			hashValue = Object.keys(paramObj).map(function (key) {
				return key + '=' + paramObj[key];
			}).join('&');
		}
		window.location.hash = "?" + hashValue;
	};
		
	var hashParam = window.location.hash,
		hashParamObj = "";
	if (hashParam && solutionPagesize) {
		hashParamObj = parseHashParms(hashParam);
		if(hashParamObj && hashParamObj.DatasourceId){
			solutionFilterVal = hashParamObj.DatasourceId;
			
			$("#solutionFilter .custom-select-list li").each(function(){
				if($(this).attr('data-list-value') === hashParamObj.DatasourceId){
					$(this).addClass("active").siblings().removeClass("active");
					$("#solutionFilter input").val($(this).text());
				}
			})
		}
		
	}
				
	var fetchSolutionData = function(pageNo, pageSize){		
		$("#solutionPaginationBackend").hide();
		$("#solutionDataFromBackend").hide();
		$("#solutionListDataFromBackend").hide();
		if(solutionLIstView == "True") {
			$(".more-solutions").hide();
		}
		$.post("/api/solutionsearch/getsolution", {
			"Datasource_Id": solutionFilterVal,
			"PageNo" : pageNo,
			"PageSize": pageSize,
			"ShowListView": solutionLIstView
        }, function (data) {
            var solutionData = "", solutionLIstData = "";
			if(data.respone.products && data.respone.products.length){
				data.respone.products.forEach(item => {
					solutionData += `
						<div class="card-list">
							<img src="${item.image ? item.image : '/-/media/tech/omdia/herobannerwithtile/herobannerwithtileplaceholder.jpeg'}">
							<div class="card-list-content">
								<h3 class="card-list-title"><a href="${item.url}">${item.title}</a></h3>
								<p>${item.body}</p>
								<a href="${item.url}" class="cta cta-arrow-right">${item.urlText}</a>
							</div>
					</div>`;
				});
			}
			if(data.respone.listViewProducts && data.respone.listViewProducts.length > 0){
				data.respone.listViewProducts.forEach(function (item) {
					solutionLIstData += `
						<li>
							<svg>
								<use xlink:href="/dist/img/svg-sprite.svg#arrow_right"></use>
							</svg>
							<a href='${item.url}'>${item.title}</a>
						</li>
					`
				});
				if(solutionLIstView == "True") {
					$(".more-solutions").show();
				}
			}
			if(data.respone && data.respone.listViewTitle){
				$("#more-solutions-list-title").text(data.respone.listViewTitle);
			}
			$("#solutionDataFromFrontend").html(solutionData);
			$("#solutionListDataFromFrontend").html(solutionLIstData);
			$("#solutionPagination").pagination({
				dataTotalLength: data.respone.count,
				currentPageItem: pageNo,
				dataPerPage: pageSize
			});
        });
    }
	if(solutionPagesize && solutionDropdown == "True"){
		if(hashParamObj && hashParamObj.page){
			fetchSolutionData(hashParamObj.page, solutionPagesize);
		} else {
			fetchSolutionData(1, solutionPagesize);
		}
	}
	
	$("#solutionPagination").on('click', "li", function (e) {
		e.preventDefault();
		var pageIndex = $(this).find("a").attr("data-index");
		updateHashParam("page", pageIndex);
		fetchSolutionData(pageIndex, solutionPagesize);
	});
	
	$("#solutionFilter").on('click', '.custom-select-list li', function () {
		solutionFilterVal = $(this).attr('data-list-value');	
			
		fetchSolutionData(1, solutionPagesize);
		updateHashParam("DatasourceId", solutionFilterVal);
		if(hashParamObj.page){
			updateHashParam('page', "");
		}
		$("#solutionPagination").pagination({
			currentPageItem: firstPage,
		});
	});

	$(".header-banner .arrow-list li").on("click", function(e){
		if($(this).attr("anchor-id") != "" && $(this).attr("anchor-id") != null) {
			e.preventDefault();
			solutionFilterVal = $(this).attr("anchor-id");
			fetchSolutionData(1, solutionPagesize);
			updateHashParam("DatasourceId", solutionFilterVal);
			if (hashParamObj.page) {
				updateHashParam('page', "");
			}
			$("#solutionPagination").pagination({
				currentPageItem: firstPage
			});
			$("#solutionFilter .custom-select-list li").forEach(function(item){
				var listVal = $(item).text();
				$(item).removeClass("active");
				if(solutionFilterVal == $(item).attr('data-list-value')){
					$(item).addClass("active");
					$("#solutionFilter .custom-select-value").val(listVal);
				}
			});
			window.scrollTo({
				top: $('#solutionFilter').offset().top - 150,
				behavior: "smooth"
			});
		}
	});

	$(".header-banner .arrow-list li").each(function(){
		if($(this).attr("anchor-id") != "" && $(this).attr("anchor-id") != null) {
			$(this).find("span").css({"cursor": "pointer"})
		}
	})
	
    $.fn.pagination = function (options) {		
		var _this = this,
			defaults = {
				dataPerPage: 10,
				populatePageCount: 5,
				startPageFrom: 1,
			},
			settings = $.extend({}, defaults, options),
			firstPageIndex = 1,
			initPageIndex = settings.startPageFrom,
			lastPageIndex = settings.populatePageCount,
			pageIncrement,
			activePageIndex = 1,
			totalPageCount = "",
			currentPageItem = settings.currentPageItem;
			
			
		if(this.length > 1){
			this.each(function(){ $(this).pagination(options) });
			return this;
		}
	
		this.populateData = function(){
			totalPageCount = Math.ceil(settings.dataTotalLength / settings.dataPerPage);
			var paginationItem = "";
				
			if(initPageIndex != firstPageIndex){
				paginationItem += `<li>
									<a href="#?page=${firstPageIndex}" data-index="${firstPageIndex}" title="Page ${firstPageIndex}">First</a>
								   </li>
								   <li>...</li>`;
			}
			
			for (var i = initPageIndex; i <= totalPageCount; i++) {
				if(i <= lastPageIndex){
					if(activePageIndex && activePageIndex == i){
						paginationItem += `<li class="active">
											<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
										  </li>`;
					}else{					
						paginationItem += `<li>
											<a href="#?page=${i}" data-index="${i}" title="Page ${i}">${i}</a>
										  </li>`;
					}
				}
			}
			
			if(totalPageCount > lastPageIndex){
				paginationItem += `<li>...</li>`
				
				if(totalPageCount == activePageIndex){
					paginationItem += `<li class"active">
										<a href="#?page=${totalPageCount}" data-index="${totalPageCount}" title="Page ${totalPageCount}">Last</a>
									  </li>`;
				}else {
					paginationItem += `<li>
										<a href="#?page=${totalPageCount}" data-index="${totalPageCount}" title="Page ${totalPageCount}">Last</a>
									  </li>`;
				}
			}
			if (totalPageCount <= firstPageIndex) {
				paginationItem = "";
			}
			this.html(paginationItem);	
		}
		
		this.initialize = function(){
			_this.populateData();
				
			if(currentPageItem){
				activePageIndex = currentPageItem;
			}

			if (currentPageItem && currentPageItem > 2 && currentPageItem <= totalPageCount - 2) {
				initPageIndex = Number(currentPageItem) - 2;
				lastPageIndex = Number(currentPageItem) + 2;
			}
			if (currentPageItem && currentPageItem <= 2) {
				initPageIndex = settings.startPageFrom,
				lastPageIndex = settings.populatePageCount
			}
			if(totalPageCount > 5){
				if (currentPageItem && currentPageItem >= totalPageCount - 2) {
					initPageIndex = totalPageCount - 4;
					lastPageIndex = totalPageCount;
				}
			}
			_this.populateData();
		};
		this.initialize();		
			
		$(_this).on('click', "li", function(e){
			e.preventDefault();
			//e.stopPropagation();
			currentPageItem = $(this).find("a").attr("data-index");
			_this.initialize();
		});
	};
});