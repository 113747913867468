$(document).ready(function () {
    if ($('#GeneralRTEComponentForTrial').length != 0) {
        var etype = getFreeTrialParameterByName("eType", window.location.href);
        if (etype && etype.trim().toLowerCase() === "std") {
            var returnUrl = getFreeTrialParameterByName("returnUrl", window.location.href);
            returnUrl = returnUrl != "null" ? returnUrl : '/';
            window.location.href = returnUrl;
        } else {
			processFreeTrialRegistration(false);
		}
    }

    $('#complete-email-verification-for-trial').on("click", function () {
        processFreeTrialRegistration(true);
    });

    if ($('#email-verification-refresh-interval').length > 0) {
        var interval = $('#email-verification-refresh-interval').val();
        if (interval != 0) {
            setTimeout(refreshUserEmailVerificationStatus, interval * 1000);
        }
    }
    displayNotification();
});

function displayNotification() {
    var $notificationMessage = $('.email-verification-error-message');
    if ($notificationMessage && $notificationMessage.length > 0) {
        $notificationMessage.hide();
    }
    var $nextCTAErrorMessage = $('.email-verification-next-cta-error-message');
    if ($nextCTAErrorMessage && $nextCTAErrorMessage.length > 0) {
        $nextCTAErrorMessage.hide();
    }
    var today = new Date();
    var expiresDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toUTCString();
    var cookie = document.cookie;
    var cookieArray = cookie.split(';');
    cookieArray.forEach(function (item) {
        item = item.trim();
        if (item.indexOf('notifiyuseraboutemailverification=') >= 0) {
            var value = item.split('=')[1];
            if (value.length > 0) {
                value = value.trim().toLowerCase();
                if (value == 'true' && $notificationMessage && $notificationMessage.length > 0) {
                    $notificationMessage.show();
                }
            }
            document.cookie = 'notifiyuseraboutemailverification=False;path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
        }
    });
}

//Execute only if user is still on email verification page
function refreshUserEmailVerificationStatus() {
    if ($('#email-verification-refresh-interval').length > 0) {
        var interval = $('#email-verification-refresh-interval').val();
        if (interval != 0) {
            var type = getFreeTrialParameterByName("type", window.location.href);
            if (!(type && type == "register")) {
                processFreeTrialRegistration(false);
            }
        }
    }
}

function processFreeTrialRegistration(onclickhandler) {
    var returnUrl = getFreeTrialParameterByName("returnUrl", window.location.href);
    returnUrl = returnUrl != "null" ? returnUrl : '/';

    if (IsTrialProcessingStarted()) {
        validateUserEntitlement(onclickhandler, returnUrl);
    } else {
        var type = getFreeTrialParameterByName("type", window.location.href);
        // if user clicked on complete your trail button on email verification
        // page (means email verification is enabled) and it is the case of non trial registration.
        if (onclickhandler && type && type == "register") {
            window.location.href = returnUrl;
        } else {
            validateUserEntitlement(false, returnUrl);
        }
    }
}

function validateUserEntitlement(onclickhandler, returnUrl) {
    jQuery.ajax({
        url: "account/api/RequestFreeTrialApi/CheckEntitlementStatus",
        method: "GET",
        dataType: "json",
        success: function success(response) {
            if (response.success) {
                var reponseMessage = response.message.replace("{here}", "<a href=" + returnUrl + ">here</a>");
                $("#GeneralRTEComponentForTrial").html(reponseMessage);
                setTimeout(function () {
                    if (returnUrl != "" || returnUrl != "undefined") {
                        DeleteIsFreeTrialCookie();
                        var $RegisterOrEnrollmentLink = $('#RegisterOrEnrollmentLink');
                        if ($RegisterOrEnrollmentLink) {
                            var RegisterOrEnrollmenFullUrl = $RegisterOrEnrollmentLink.val().trim() + "?returnUrl=" + returnUrl;
                            window.location.href = RegisterOrEnrollmenFullUrl;
                        }
                    }
                }, 5000);
            } else {
                checkContactinfo(onclickhandler, returnUrl);
            }
        }
    });
}

function checkContactinfo(onclickhandler, returnUrl) {
    jQuery.ajax({
        url: "account/api/RequestFreeTrialApi/NeedToUpdateDetails",
        method: "GET",
        dataType: "json",
        success: function success(response) {
            //if response is true then redirect user to account update with trial processing page as return url
            if (response.result) {
                var $MyAccountPageUrl = $('#MyAccountPageUrl');
                var $TrialProcessingPage = $('#trialProcessingPage');
                if ($MyAccountPageUrl && $TrialProcessingPage) {
                    var MyAccountPageFullUrl = $MyAccountPageUrl.val().trim() + "?istrial=true&onSuccess=" + encodeURIComponent($TrialProcessingPage.val().trim() + "?istrial=true&returnUrl=" + returnUrl);
                    window.location.href = MyAccountPageFullUrl;
                }
            } else {
                var status = getFreeTrialParameterByName("status", window.location.href);
                if (status && status === "completed") {
                    jQuery.ajax({
                        url: "account/api/PreferencesApi/IsUserPreferencesAvailable?returnUrl=" + returnUrl,
                        method: "GET",
                        dataType: "json",
                        success: function success(response) {
                            if (response.success) {
                                UpdateFreeTrialCookie(response.enrolmentLink);
                                window.location.href = response.enrolmentLink;
                            } else {
                                if (IsTrialProcessingStarted() || WasFreeTrialRequest()) {
                                    requestFreeTrial();
                                } else {
                                    window.location.href = '/';
                                }
                            }
                        }
                    });
                } else {
                    VerifyUserEmail(onclickhandler, returnUrl);
                }
            }
        }
    });
}

function requestFreeTrial() {
    var pubCode = getFreeTrialParameterByName("PubCode", window.location.href);
    jQuery.ajax({
        url: "account/api/RequestFreeTrialApi/RequestFreeTrial?pubCode=" + pubCode,
        method: "GET",
        dataType: "json",
        success: function success(response) {
            if (response.success) {
                var returnOrigin = getFreeTrialParameterByName("returnUrl", window.location.href);
                var reponsemessage = response.message.replace("{here}", "<a href=" + returnOrigin + ">here</a>");
                jQuery("#GeneralRTEComponentForTrial").html(reponsemessage);
                setTimeout(function () {
                    if (returnOrigin != "" || returnOrigin != "undefined") {
                        DeleteIsFreeTrialCookie();
                        window.location.href = returnOrigin;
                    }
                }, 5000);
            } else {
                jQuery("#GeneralRTEComponentForTrial").html(response.message);
            }
        }
    });
}

function DeleteIsFreeTrialCookie() {
    var cookies = document.cookie;
    var cookieArray = cookies.split(';');
    cookieArray.forEach(function (item) {
        item = item.trim();
        if (item.indexOf('isFreeTrial=') >= 0) {
            var today = new Date();
            var expiresDate = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate()).toUTCString();
            document.cookie = 'isFreeTrial=False;path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
        }
    });
}

function UpdateFreeTrialCookie(url) {
    var today = new Date();
    var expiresDate = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate()).toUTCString();
    var cookie = document.cookie;
    var cookieArray = cookie.split(';');
    cookieArray.forEach(function (item) {
        item = item.trim();
        if (item.indexOf('isFreeTrial=') >= 0) {
            var oldValue = item.split('=')[1];
            if (oldValue.length > 0) {
                if (oldValue.indexOf('|') >= 0) {
                    document.cookie = 'isFreeTrial=' + oldValue.substring(0, oldValue.indexOf('|') + 1) + url + ';path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
                } else {
                    document.cookie = 'isFreeTrial=' + oldValue + '|' + url + ';path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
                }
            }
        } else {
            document.cookie = 'isFreeTrial=True|' + url + ';path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
        }
    });
}

function IsTrialProcessingStarted() {
    var result = false;
    var istrial = getFreeTrialParameterByName("istrial", window.location.href);
    if (istrial) {
        result = true;
    }
    if (result) {
        return result;
    }
    var cookie = document.cookie;
    var cookieArray = cookie.split(';');
    cookieArray.forEach(function (item) {
        item = item.trim();
        if (item.indexOf('isFreeTrial=') >= 0) {
            var oldValue = item.split('=')[1];
            if (oldValue.indexOf('|') >= 0) {
                var parts = oldValue.split('|');
                oldValue = parts[0];
            }
            if (oldValue == "True") {
                result = true;
            }
        }
    });
    return result;
}

function WasFreeTrialRequest() {
    var result = false;

    var cookie = document.cookie;
    var cookieArray = cookie.split(';');
    cookieArray.forEach(function (item) {
        item = item.trim();
        if (item.indexOf('isFreeTrial=') >= 0) {
            var oldValue = item.split('=')[1];
            if (oldValue.indexOf('|') >= 0) {
                var parts = oldValue.split('|');
                oldValue = parts[0];
            }
            if (oldValue.toLowerCase() == "false") {
                result = true;
            }
        }
    });
    return result;
    }

function VerifyUserEmail(onclickhandler, returnUrl) {
    jQuery.ajax({
        url: "account/api/RequestFreeTrialApi/IsEmailVerified",
        method: "GET",
        dataType: "json",
        success: function success(response) {
            if (response) {
                if (!onclickhandler) {
                    RedirectToEnrollmentPage(returnUrl);
                } else {
                    RedirectToTrialProcessingPage(returnUrl);
                }
            } else {
                if (!onclickhandler) {
                    RedirectToEmailVerificationPage(returnUrl);
                } else {
                    ShowNextCtaErrorMessage();
                }
            }
        }
    });
}

function RedirectToEnrollmentPage(returnUrl) {
    var $RegisterOrEnrollmentLink = $('#RegisterOrEnrollmentLink');
    if ($RegisterOrEnrollmentLink) {
        var RegisterOrEnrollmenFullUrl = $RegisterOrEnrollmentLink.val().trim() + "?returnUrl=" + returnUrl + "&istrial=true";
        UpdateFreeTrialCookie(RegisterOrEnrollmenFullUrl);
        window.location.href = RegisterOrEnrollmenFullUrl;
    }
}

function RedirectToEmailVerificationPage(returnUrl) {
    var $idVerificationPageURL = $('#EmailVerificationPage');
    if ($idVerificationPageURL) {
        var idVerificationPageFullUrl = $idVerificationPageURL.val().trim() + "?returnUrl=" + returnUrl + "&istrial=true";
        UpdateFreeTrialCookie(idVerificationPageFullUrl);
        window.location.href = idVerificationPageFullUrl;
    }
}

function RedirectToTrialProcessingPage(returnUrl) {
    window.location.href = jQuery('#trialProcessingPage').val() + "?returnUrl=" + returnUrl + "&istrial=true";
}

function ShowNextCtaErrorMessage() {
    //Show error message specific to email verification if user clicked on next button and email is still not verified.
    var $notificationMessage = $('.email-verification-error-message');
    if ($notificationMessage && $notificationMessage.length > 0) {
        $notificationMessage.hide();
    }
    var $nextCTAErrorMessage = $('.email-verification-next-cta-error-message');
    if ($nextCTAErrorMessage && $nextCTAErrorMessage.length > 0) {
        $nextCTAErrorMessage.show();
    }
}

function getFreeTrialParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'i'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}