var elements = document.querySelectorAll("#product-listing-content li"),
    productList = document.querySelector(".product-name-list ul"),
    modal = document.querySelector("#product-listing-content"),
    modalBtn = document.querySelector("[data-target='#product-listing-content']"),
    modalClose = document.querySelector("#product-listing-content .close"),
    body = document.body,
    backdrop = null,
    modalvisiblity = null,
    ulHeight = 0;

function removeBackdrop(ele){
    ele.addEventListener("click", function(){
        if(modalvisiblity){
            modal.classList.remove("show");
        }
        ele.remove();
    })
}

if(modalBtn && modal) {
    modalBtn.addEventListener("click", function(event){
        event.preventDefault();
        modal.classList.add("show");
        body.insertAdjacentHTML('beforeend', '<div class="modal-backdrop-bg"></div>');
        backdrop = document.querySelector(".modal-backdrop-bg");
        if(backdrop){
            removeBackdrop(backdrop);
        }
        modalvisiblity = modal.classList.contains("show");
        if(modalvisiblity && productList && elements){
            ulHeight = 0;
            for (var i = 0; i < elements.length; i++) {
                if(i < 5){
                    ulHeight += elements[i].clientHeight;
                    productList.style.maxHeight = ulHeight + "px";
                }
            }
        }
    });
}

if(modalClose) {
    modalClose.addEventListener("click", function(){
        modal.classList.remove("show");
        backdrop.remove();
        productList.style.maxHeight = "none";
    });
}