(function () {
        var RecomendedContent = {
            AjaxData: function (url, type, data, SuccessCallback) {
                $.ajax({
                    url: url,
                    data: JSON.stringify(data),
                    dataType: 'json',
                    contentType: "application/json",
                    type: type,
                    cache: false,
                    success: function (data) {
                        //if (data.articles && typeof data.articles === "object" && data.articles.length >= 3) {
                        SuccessCallback(data);
                        //} 
                    }
                });
            },
            RecomendedTemplate: function (data) {
                var Template = '';
                var HeadingAnalytics = $('.ContentRecomm-ReadNext').find('h2').text(),
                articleId = $('#hdnCurrentArticleItemID').val(),
                items=0;

                for(var i=0; i<data.articles.length; i++){
                    if(data.articles[i].id === articleId){
                        data.articles.splice(i,1);
                    }
                }

                if(data.articles.length < 3){
                    items = data.articles.length;
                }
                else{
                    items = 3;
                }

                if (Array.isArray(data.articles) && data.articles.length > 0) {
                    for (var j = 0; j < 3; j++) {
						var addCls = (data.articles[j].isSonsoredBy) ? 'sponsored_cont' : '';
							var sponsoredByLogo = (data.articles[j].sponsoredLink && data.articles[j].sponsoredByLogo) ? '<li><a href="'+data.articles[j].sponsoredLink+'"><img src="'+data.articles[j].sponsoredByLogo+'"></a></li>' : ((data.articles[j].sponsoredLink == null || data.articles[j].sponsoredLink === undefined) && data.articles[j].sponsoredByLogo) ? '<img src="'+data.articles[j].sponsoredByLogo+'">' : '',
								sponsoredByTitle = (data.articles[j].sponsoredByTitle) ? '<li><time class="article-metadata__date sponsored_title">'+data.articles[j].sponsoredByTitle+'</time></li>' : '',
								listablePublication = (data.articles[j].listablePublication) ? '<span class="article-related-content__category"> ' + data.articles[j].listablePublication + ' </span>' : '',
								listableImage = (data.articles[j].listableImage) ? '<a href="' + data.articles[j].linkableUrl + '"><img class="article-related-content__img" src="' + data.articles[j].listableImage + '"></a>' : '',
								listableDate = (data.articles[j].listableDate) ? '<time class="article-related-content__date">' + data.articles[j].listableDate + '</time>' : '',
								listableTitle = (data.articles[j].listableTitle) ? '<h5><a class="click-utag" data-info=\'{"event_name":"article_click_through,recommendation_content","page_name":"' + analytics_data["page_name"] + '","click_through_destination":"' + data.articles[0].listableTitle.replace(/'/g, "").replace(/"/g, '') + '","ga_eventCategory":"What to read next","ga_eventAction":"' + HeadingAnalytics + '","ga_eventLabel":"' + data.articles[j].listableTitle.replace(/'/g, "").replace(/"/g, '') + '","publication_click":"' + analytics_data["publication"] + '","recommendation_category":"What to read next","link_type":"' + data.articles[j].linkType +'"}\' href="' + data.articles[j].linkableUrl + '">' + data.articles[j].listableTitle + '</a></h5>' : '',
								articlemeta = (sponsoredByTitle === '' && sponsoredByLogo === '') ? '' : '<div class="article-metadata"><ul>'+ sponsoredByTitle + sponsoredByLogo +'</ul></div>';
							if(data.articles[j].listableImage == null) { 
								Template += '<div class="section-article '+addCls+'">' +
												articlemeta + listablePublication + listableTitle + listableDate +
											'</div>';
                        } else {
								Template += '<div class="section-article '+addCls+'">' +
												articlemeta + listableImage +  listablePublication +  listableTitle + listableDate +
											'</div>';
                        }
                    }
                } else {
                    $('.ContentRecomm-ReadNext').hide();
                }

                Template += '</div>';
				
                $('.ContentRecomm-ReadNext').append(Template);

            },
            SuggestedTemplate: function (data) {
                var Template = '';
                var HeadingAnalytics = $('.suggested-article').find('h2').text(),
                articleId = $('#hdnCurrentArticleItemID').val(),
                items=0;

                for(var i=0; i<data.articles.length; i++){
                    if(data.articles[i].id === articleId){
                        data.articles.splice(i,1);
                    }
                }

                if(data.articles.length < 3){
                    items = data.articles.length;
                }
                else{
                    items = 3;
                }
                
                if (Array.isArray(data.articles) && data.articles.length > 0) {
                    for (var j = 0; j < 3; j++) {
						var addCls = (data.articles[j].isSonsoredBy) ? 'sponsored_cont' : '',
							sponsoredByLogo = (data.articles[j].sponsoredLink && data.articles[j].sponsoredByLogo) ? '<li><a href="'+data.articles[j].sponsoredLink+'"><img src="'+data.articles[j].sponsoredByLogo+'"></a></li>' : ((data.articles[j].sponsoredLink == null || data.articles[j].sponsoredLink === undefined) && data.articles[j].sponsoredByLogo) ? '<img src="'+data.articles[j].sponsoredByLogo+'">' : '',
							sponsoredByTitle = (data.articles[j].sponsoredByTitle) ? '<li><time class="article-metadata__date sponsored_title">'+data.articles[j].sponsoredByTitle+'</time></li>' : '',
							listablePublication = (data.articles[j].listablePublication) ? '<span class="article-related-content__category"> ' + data.articles[j].listablePublication + ' </span>' : '',
							listableImage = (data.articles[j].listableImage) ? '<a href="' + data.articles[j].linkableUrl + '"><img class="article-related-content__img" src="' + data.articles[j].listableImage + '"></a>' : '',
							listableDate = (data.articles[j].listableDate) ? '<time class="article-related-content__date">' + data.articles[j].listableDate + '</time>' : '',
							listableTitle = (data.articles[j].listableTitle) ? '<h5><a class="click-utag" data-info=\'{"event_name":"article_click_through,recommendation_content","page_name":"' + analytics_data["page_name"] + '","click_through_destination":"' + data.articles[0].listableTitle.replace(/'/g, "").replace(/"/g, '') + '","ga_eventCategory":"Suggested for you","ga_eventAction":"' + HeadingAnalytics + '","ga_eventLabel":"' + data.articles[j].listableTitle.replace(/'/g, "").replace(/"/g, '') + '","publication_click":"' + analytics_data["publication"] + '","recommendation_category":"Suggested for you","link_type":"' + data.articles[j].linkType +'"}\' href="' + data.articles[j].linkableUrl + '">' + data.articles[j].listableTitle + '</a></h5>' : '',
							articlemeta = (sponsoredByTitle === '' && sponsoredByLogo === '') ? '' : '<div class="article-metadata"><ul>'+ sponsoredByTitle + sponsoredByLogo+ '</ul></div>';
							
                        if(data.articles[j].listableImage == null) {
							//if(sponsoredByTitle && sponsoredByLogo && listablePublication && listableTitle && listableDate){
                            Template += '<div class="contentRecomm-article '+addCls+'">' +
											articlemeta + listablePublication + listableTitle + listableDate +
										'</div>';
							//}
                        } else {
                        Template += '<div class="contentRecomm-article '+addCls+'">' +
										articlemeta + listableImage + listablePublication + listableTitle + listableDate +
									'</div>';
						}   
					}
                } else {
                    $('.suggested-article').hide();
                }

                Template += '</div>';

                $('.suggested-article').append(Template);
            },
            init: function () {
                var self = this;
                if ($('.ContentRecomm-ReadNext').length > 0) {
                    self.AjaxData('/api/articlesearch', 'POST', { 'TaxonomyIds': $('#hdnTaxonomyIds').val().split(","), 'PageNo': 1, 'PageSize': 4 }, self.RecomendedTemplate);
                }
                if ($('#hdnPreferanceIds').val()) {
                    self.AjaxData('/api/articlesearch', 'POST', { 'TaxonomyIds': $('#hdnPreferanceIds').val().split(",").slice(0, 100), 'PageNo': 1, 'PageSize': 4 }, self.SuggestedTemplate);
                }
            }
        }

        RecomendedContent.init();
    })();
