
setTimeout(function(){$('.selectivity-input').on('change', function(event)
{
  var formIframe;
  if(event.currentTarget.id !== 'natureOfEnquiry'){
    return;
  }
  if(event.currentTarget.selectivity._value){
    formIframe = event.currentTarget.parentElement.parentElement.getElementsByTagName('iframe')[0];
    if(formIframe){
      if(event.currentTarget.selectivity._value.includes("Select One")){
        formIframe.src = "";
        formIframe.style.display = 'none';
      }else{
        formIframe.src = event.currentTarget.selectivity._value;
      }
    }
  }
});}, 0);

