var modal = document.querySelector("#advanced-search-options"),
    modalBtn = document.querySelector(".advanced-search .advanced-search-toggle"),
    modalClose = document.querySelector("#advanced-search-options .close"),
    applySearchBtn = document.querySelector(".advanced-search-btn"),
    body = document.body,
    backdrop = null,
    modalvisiblity = null;

function removeBackdrop(ele){
    if(modalvisiblity){
        modal.classList.remove("show");
    }
    ele.remove();
}

if(modalBtn && modal) {
    modalBtn.addEventListener("click", function(event){
        event.preventDefault();
        modal.classList.add("show");
        modalvisiblity = true;
        body.insertAdjacentHTML('beforeend', '<div class="modal-backdrop-bg"></div>');
        backdrop = document.querySelector(".modal-backdrop-bg");
        if(backdrop){
            backdrop.addEventListener("click", function(){
                removeBackdrop(backdrop);
            })
        }        
    });
}

if(modalClose || applySearchBtn) {
    modalClose.addEventListener("click", function(){
        removeBackdrop(backdrop);
    });
    applySearchBtn.addEventListener("click", function(){
        removeBackdrop(backdrop);
    });
}