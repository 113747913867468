$(function () {
  var CHECK_TIMER = 30,
      isThemeOmdia = $('body').hasClass('theme-omdia'),
      tableauCheck,
      tableauCheckTimerEvent,
      $tableaus,
      $tableausLegacy,
      $iframe,
      presetIframeWidth,
      presetFIframeHeight,
      tableauSetup = function($tabl) {
        $iframe = $tabl.find('iframe');

        // set class to content container for outer styling
        $tabl.closest('.inf-container').addClass('tableau-dashboard-container');

        // don't continue without iframe rendered
        if ($iframe.length === 0) return;

        // store iframe dimensions
        presetIframeWidth = $iframe.width();
        presetFIframeHeight = $iframe.height();

        // don't continue without iframe rendered fully
        if (presetIframeWidth < 10 && presetFIframeHeight < 10) return;

        // set data attributes for reference
        $iframe.attr('data-original-width', presetIframeWidth);
        $iframe.attr('data-original-height', presetFIframeHeight);
    
        // iframe fluid
        $iframe.css('width', '100%');

        // content container set width
        $tabl.closest('.root').css('max-width', presetIframeWidth);

        // add checked class to prevent re-run
        $tabl.addClass('checked');
      },
      tableauLegacySetup = function($tablLeg) {
        $iframe = $tablLeg.find('iframe');

        // set class to content container for outer styling
        $tablLeg.closest('.inf-container').addClass('tableau-dashboard-container');

        // don't continue without iframe rendered
        if ($iframe.length === 0) return;

        // store iframe dimensions
        presetIframeWidth = $iframe.width();
        presetFIframeHeight = $iframe.height();

        // don't continue without iframe rendered fully
        if (presetIframeWidth < 10 && presetFIframeHeight < 10) return;

        // set data attributes for reference
        $iframe.attr('data-original-width', presetIframeWidth);
        $iframe.attr('data-original-height', presetFIframeHeight);
    
        // set width to fluid
        $iframe.css('width', '100%');

        // add checked class to prevent re-run
        $tablLeg.addClass('checked');
      },
      tableauCheck = function() {
        $tableaus = $('.tableau-app').not('.checked');
        $tableausLegacy = $('.article-data-tool-placeholder').not('.checked');
        if ($tableaus.length > 0) {
          tableauSetup($tableaus.first());
        }
        if ($tableausLegacy.length > 0) {
          tableauLegacySetup($tableausLegacy.first());
        }
      }

  if (!isThemeOmdia) return;

  tableauCheckTimerEvent = setInterval(tableauCheck, 200);
  tableauCheck();
  
  // stop checking for tableaus after X seconds
  setTimeout(function() {
    clearInterval(tableauCheckTimerEvent);
  }, CHECK_TIMER * 1000);
});