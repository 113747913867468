$(function(){
	$(".header-banner").each(function(){
        var color = $(this).data("color");
        $(this).find('.header-banner-card__right').css('backgroundColor', color);
        $(this).find('.header-banner-card__left h2').css('color', color);
        $(this).find(".header-banner-card__left svg").css("fill", color);

        var imgHeight = $(this).find('.header-banner-card__right').height() / 2;
        if ($(window).width() <= 800) {
            $(this).children('img').css({
                height: ($(this).height() - imgHeight + 'px')
            });
        }
    })
});

