$(".accordian-hide").click(function(){
  var content = $( ".accordian-content" );
  content.toggle();
  $(".accordian-hide").hide();
  $(".accordian-show").show();
});


$(".accordian-show").click(function(){
  var content = $( ".accordian-content" );
  content.toggle();
  $(".accordian-hide").show();
  $(".accordian-show").hide();
});

// accordion for collection component and product directory page
$( ".accordion-panel").each(function(){
  var accordianPanel = $(this),
      accordianHead = $(this).children(".accordion-header"),
      accordianContent = $(this).children(".accordion-body");

  accordianHead.click(function(){
    accordianPanel.toggleClass("accordion-expanded");
  });
});

// accordion for help and support page
$( ".accordion-tile-panel").each(function(){
  var accordionPanel = $(this),
      accordionHead = $(this).children(".accordion-tile-header"); 

  accordionHead.click(function(){
    accordionPanel.toggleClass("accordion-tile-expanded");
  });
});