(function ($) {
  var $infMenu,
      $body,
      $header,
      $main,
      $infMenuBtn,
      isMobile,
      $mainNav,
      $topTierItems,
      $topTierItem,
      $subMenuContainer,
      $subMenus,
      $subMenu,
      $overlay,
      $accountDropdownBtn,
      $accountDropdown,
      $searchbarHeader,
      $searchField,
      $searchbarClose,
      $searchSubmit,
      $searchToggle,
      $mobSubBack,
      $mobSubBackBtn,
      // must match vars in scss (TODO: set values from element's transition css props)
      toggleIconDelay = 200,
      mobileMenuOpenDuration = 400,
      // methods
      resetTimeout, openAnimation, closeAnimation, toggleAnimation, followSearch, createMobileBack;

  if (!$('body').hasClass('theme-omdia')) return;

  resetTimeout = function($el, timerId) {
    if ($el.data(timerId)) {
      clearTimeout($el.data(timerId));
      $el.data(timerId, null);
    }
  }

  openAnimation = function($el, delay, onOpenCallback) {
    // console.log('opening');
    $el.addClass('opening').removeClass('closing');
    resetTimeout($el, 'openingTimer');
    resetTimeout($el, 'closingTimer');
    $el.data('openingTimer', setTimeout(function() {
      // console.log('open');
      $el.removeClass('opening').addClass('open');
      if (onOpenCallback) {
        onOpenCallback();
      }
    }, delay || 0));
  }

  closeAnimation = function($el, delay, onCloseCallback) {
    // console.log('closing');
    $el.removeClass('open opening').addClass('closing');

    resetTimeout($el, 'openingTimer');
    resetTimeout($el, 'closingTimer');
    $el.data('closingTimer', setTimeout(function () {
      // console.log('closed');
      $el.removeClass('closing');
      if (onCloseCallback) {
        onCloseCallback();
      }
    }, delay || 0));
  }

  toggleAnimation = function ($el, delay, onOpenCallback, onCloseCallback) {
    if ($el.hasClass('open') || $el.hasClass('opening')) {
      closeAnimation($el, delay, onCloseCallback);
    } else {
      openAnimation($el, delay, onOpenCallback);
    }
  }

  createMobileBack = function () {
    $subMenuContainer = $('.mob-sub-container');
    
    $mobSubBackBtn = $('<a href="#">Back</a>');
    $mobSubBack = $('<div class="mob-sub-back" />');

    $mobSubBack.append($mobSubBackBtn);
    $subMenuContainer.append($mobSubBack);

    $subMenuContainer.find(".mob-sub-back").on('click', function (e) {
      e.preventDefault();
      // remove 'submenu-open' class from $infMenu
      $('.mob-sub-container').removeClass('mob-sub-open main-nav-sub account-access-sub');
      // remove 'open' class from 'mob-sub-container.open'
      closeAnimation($('.mob-sub-container.open'), mobileMenuOpenDuration);
      // remove 'open' class from self
      closeAnimation($mobSubBack, mobileMenuOpenDuration);
    });
  };

  // cross device properties assignment
  $body = $('body');
  $header = $('header');
  $infMenu = $('.inf-main-menu');
  $searchbarHeader = $('.searchbar-header');
  $searchSubmit = $('.search-submit');
  $searchField = $('.searchbar-header').find('input[type="search"]');
  $searchbarClose = $('.searchbar-header').find('.searchbar-close');
  $searchToggle = $('.search-toggle');
  $mainNav = $infMenu.find('.main-nav');
  $topTierItems = $mainNav.children('li').children('a');
  $subMenus = $topTierItems.siblings('div');
  $accountDropdownBtn = $('.account-dropdown-btn');
  $accountDropdown = $accountDropdownBtn.siblings('.account-dropdown');
  isMobile = $(window).width() <= 1201;

  var $removeSearchActive, $hideSearchbarActive;
  // cross device listeners
  // ISW-11858: hack to override search page killing pills
  if ($('#searchTop').length > 0) {
    $('.trending_tags a').on('click', function (e) {
      var $this = $(this),
          href = $this.attr('href'),
          target = $this.attr('target') || '_self';
      
      if (target === '_self') {
        e.preventDefault();
        window.location = href;
        window.location.reload();
      }
    });
  }
  $searchSubmit.on('click', function (e) {
    e.preventDefault();
    $(e.currentTarget).closest('form').submit();
  });
  
  $hideSearchbarActive = function () {
    clearTimeout($removeSearchActive);
    $removeSearchActive = setTimeout(function () {
      $header.removeClass('search-active');
    }, 300);
  };
  $searchToggle.on('click', function (e) {
    clearTimeout($removeSearchActive);
    $header.toggleClass('search-active');
    if ($searchField.length > 0) {
      if ($header.hasClass('search-active')) {
        $searchField[0].focus();
      } else {
        $searchField[0].blur();
        $searchField[0].value = "";
      }
    } else {
      console.warn('Could not find an element with selector .searchbar-header in the header element: ' + $header);
    }
  });
  $searchbarClose.on('click', function(){
    $hideSearchbarActive();
    $searchField[0].value = "";
  })

if (isMobile) {
  // mobile only properties assignment
  $infMenuBtn = $('.inf-menu-toggler');
  // class for items with submenus for mobile menu styling and functionality
  $topTierItems.each(function () {
    $topTierItem = $(this);
    $subMenu = $topTierItem.siblings('div');
    if ($subMenu.length > 0) $topTierItem.addClass('mob-sub-trigger');
    $subMenu.addClass('mob-sub-container');
  });
  $accountDropdownBtn.addClass('mob-sub-trigger');
  $accountDropdown.addClass('mob-sub-container');
  // one back button that sits with after nav uls
  createMobileBack();
  // submenu toggle listener for parents
  $topTierItems.on('click', function (e) {
    $topTierItem = $(this);
    $subMenu = $topTierItem.siblings('div');
    if ($subMenu.length > 0) {
      e.preventDefault();
      // add 'submenu-open' class to $infMenu
      $infMenu.addClass('mob-sub-open main-nav-sub');
      // add 'open' class to 'sub-menu-container'
      openAnimation($subMenu, mobileMenuOpenDuration);
      // add 'open' class to self
      $mobSubBack.addClass('main-nav-sub').removeClass('account-access-sub');
      openAnimation($mobSubBack, mobileMenuOpenDuration);
    }
  });
  $accountDropdownBtn.on('click', function (e) {
    e.preventDefault();
    // add 'submenu-open' class to $infMenu
    $infMenu.addClass('mob-sub-open account-access-sub');
    // add 'open' class to 'sub-menu-container'
    openAnimation($accountDropdown, mobileMenuOpenDuration);
    // add 'open' class to self
    $mobSubBack.addClass('account-access-sub').removeClass('main-nav-sub');
    openAnimation($mobSubBack, mobileMenuOpenDuration);
  });
  // event listener for menu toggle button
  $infMenuBtn.children('button').on('click', function () {
    toggleAnimation($infMenuBtn, toggleIconDelay);
    toggleAnimation($infMenu, toggleIconDelay, function () {
      $body.addClass('mob-nav-open');
    }, function () {
      $body.removeClass('mob-nav-open');
      // close all submenus
      setTimeout(function () {
        $infMenu.removeClass('mob-sub-open main-nav-sub account-access-sub');
        $mobSubBack.removeClass('open account-access-sub main-nav-sub');
        $accountDropdown.removeClass('open');
        $infMenu.find('.mob-sub-container').removeClass('open');
      }, toggleIconDelay);
    });
  });

  $(document).click(function(e){
    if($searchToggle[0] !== e.target && $searchToggle.has(e.target).length === 0 && !$searchbarHeader[0] !== e.target && $searchbarHeader.has(e.target).length === 0){
      $hideSearchbarActive();
    }
  });
} else {
  // desktop only properties assignmentv
  $main = $('main');
  $overlay = $('<div class="inf-menu-body-overlay" />');
  // set data props on main menu container for interaction
  $mainNav.data('open-submenu', $());
  // add overlay to body
  $body.append($overlay);
  // assign id's for matching when closing previous menu
  $subMenus.each(function (index) {
    $subMenu = $(this);
    $subMenu.attr('id', index + 1);
  });
  // adds toggle on click for toptier anchor for accessibility
  $topTierItems.on('click', function (e) {
    $topTierItem = $(this);
    $topTierItem.parent().toggleClass('hover').siblings().removeClass('hover');
    e.preventDefault();
  });
  // de-activates all submenus when tabbed away from main-nav OR to another top tier item
  $mainNav.on("focusout", function (e) {
    if (
      !this.contains(e.relatedTarget) ||
      $(e.relatedTarget).parent().parent().hasClass("main-nav")
    ) {
      $(this).children("li").removeClass("hover");
    }
  });
  // handle toptier hover
  $topTierItems.each(function () {
    var $topTierLi = $(this).parent();
    $topTierLi.on('mouseleave', function () {
      $(this).removeClass('hover');
    });
    $topTierLi.on('mouseenter', function () {
      $(this).addClass('hover').siblings().removeClass('hover');
    });
  })
  // adds toggle on click for accessibility
  $accountDropdownBtn.on('click', function (e) {
    $accountDropdown.toggleClass('open');
    $accountDropdownBtn.toggleClass('hover');
    e.preventDefault();
  });
  $accountDropdownBtn.on('mouseenter', function () {
    // open account dd
    $accountDropdown.addClass('open');
    $accountDropdownBtn.addClass('hover');
  });
  $accountDropdownBtn.closest('li').on('mouseleave', function () {
    // close account dd
    $accountDropdown.removeClass('open');
    $accountDropdownBtn.removeClass('hover');
  });
  // de-activates account dd when tabbed
  $accountDropdown.parent().on("focusout", function (e) {
    if (!this.contains(e.relatedTarget)) {
      $accountDropdown.removeClass('open');
      $accountDropdownBtn.removeClass('hover');
    }
  });
  
  $(document).click(function(e){
    if($searchToggle[1] !== e.target && $searchToggle.has(e.target).length === 0 && !$searchbarHeader[0] !== e.target && $searchbarHeader.has(e.target).length === 0){
      $hideSearchbarActive();
    }
  })
}



var $cookieBanner = $(".cookie-banner"),
  $informaRibbon = $(".informa-ribbon"),
  $headerWrapper = $(".header__wrapper"),
  $ribbonHeight;

  if ($cookieBanner) {
    $ribbonHeight = $informaRibbon.height() + $cookieBanner.height();
  } else {
    $ribbonHeight = $informaRibbon.height();
  }

  $(document).on("click", function () {
    if ($cookieBanner.hasClass("is-visible")) {
      $ribbonHeight = $informaRibbon.height() + $cookieBanner.height();
    } else {
      $ribbonHeight = $informaRibbon.height();
      if($(window).scrollTop() >= $ribbonHeight) {
        $searchbarHeader.css({
          'position': 'fixed',
          'top': $headerWrapper.height()
        });
      } else {        
        $searchbarHeader.css({
          'position': 'fixed',
          'top': $ribbonHeight + $headerWrapper.height()
        });
      }
    }
  });
  $(window).scroll(function () {
    // getting main menu top item height if there is cookie banner 'or' is not cookie banner
    // getting offset height on scroll
    var scrollTop = $(this).scrollTop();
    if (scrollTop >= $ribbonHeight) {
      // on scroll setting main menu position
      $body.addClass('scrolled');
      $main.css({
        paddingTop: $headerWrapper.height()
      });
      // on scroll setting searchbar position
      $searchbarHeader.css({
        'position': 'fixed',
        'top': $headerWrapper.height()
      });
    } else {
      // on scroll setting main menu position
      $body.removeClass('scrolled');
      $main.css({
        paddingTop: 0
      });
      // on scroll setting searchbar position
      $searchbarHeader.css({
        'position': 'fixed',
        'top': $header.height() - scrollTop
      });
    }
  });
})(Zepto);