﻿(function () {
    "use strict";

    function adImpression(event) {
        if (window.IIRISTracker && window.IIRISTracker.trackImpression) {
            const impressionId = window.location.host + "-" + Date.now();
            const bannerId = "iiris" + Date.now();
            const campaignId = "iiris_recommend";
            const advertiserId = "iiris";
            const targetUrl = window.location.href;

            var eventname = event == null ? '' : event.type;
            eventname = eventname == "scroll" ? "viewport" : eventname;

            const data = {
                impressionId: impressionId,
                bannerId: bannerId,
                campaignId: campaignId,
                advertiserId: advertiserId,
                targetUrl: targetUrl,
                zoneId: eventname,
            };

            var useremail = localStorage.getItem("useremail");
            if (useremail) {
                window.snowplow("setUserId", useremail);
            }

            window.IIRISTracker.trackImpression(data);
        }
    }

    function isElementInViewPort(el) {
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }
        var bounding = el.getBoundingClientRect();
        var elementHeight = el.offsetHeight;
        var elementWidth = el.offsetWidth;

        if (
            bounding.top >= -elementHeight &&
            bounding.left >= -elementWidth &&
            bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth) +
            elementWidth &&
            bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) +
            elementHeight
        ) {
            return true;
        } else {
            return false;
        }
    }

    // if the element is in viewport
    $(function () {
        $(window).scroll(function (event) {
            var el = document.getElementById("js-further-reading-articles");
            if (el && isElementInViewPort(el)) {
                adImpression(event);
            }
        });
    });

    // usage
    $("#js-further-reading-articles .asset_family_mod").each(function () {
        $(this).mouseover(function (e) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            adImpression(e);
        });
    });

    $("#js-further-reading-articles .asset_family_mod").each(function () {
        var $anchor = $(this).find(".click-utag");
        $anchor.css('pointer-events', 'none');
        $(this)
            .off("click")
            .on("click", function (e) {
                e.stopPropagation();
                e.stopImmediatePropagation();
                adImpression(e);
                $anchor[0].click();
            });
    });
})();