﻿

$(function () {

    var searchterms = [];

    $(document).ready(function () {
        $("#SearchUrl").css("display", "none");
    });


    $('#btnsearch').click(function () {
        var searchUrl = $("#hidSearchUrl").val();
        var taxonomy = $("#hidTaxonomyParam").val();
        var queryParam = "";
        if (window.location.href.split('#?').length > 1) {
            queryParam = window.location.href.split('#?')[1];
            searchUrl = searchUrl.replace(taxonomy, "") + queryParam;
        }
        else {
            searchUrl = searchUrl + "q=";
        }
        window.location.href = searchUrl;
    });

    $('#btnclear').click(function () {
        window.location = window.location.href.split('#?')[0];
    });


    $(document).on('click', '.facets__section-list--sub  li.ng-scope', function () {

        $(this).parents("ul").siblings("label").removeClass("active");
        $(this).parents("ul").parents("li").removeClass("active-grid");
        $(this).siblings("li").removeClass("active");

        $(this).parents(".facets__repeat-wrapper").siblings(".facets__repeat-wrapper").children(".facets__section-list").removeClass("active-grid");
        $(this).parents(".facets__repeat-wrapper").siblings(".facets__repeat-wrapper").children(".facets__section-list").children("li").removeClass("active-grid");
        $(this).parents(".facets__repeat-wrapper").siblings(".facets__repeat-wrapper").children(".facets__section-list").children("li").children("label").removeClass("active");
        $(this).parents(".facets__repeat-wrapper").siblings(".facets__repeat-wrapper").children(".facets__section-list").children("li").children("ul").removeClass("active-grid");
        $(this).parents(".facets__repeat-wrapper").siblings(".facets__repeat-wrapper").children(".facets__section-list").children("li").children("ul").children("li").removeClass("active");

        var classactive = $(this).hasClass("active");
        var itemkey = $(this).attr("rootAttr");
        var itemvalue = $(this).attr("attrValue")
        var isChild = $(this).attr("attrChild");
        var child = false;
        if (isChild != null) {
            child = true;
        }

        if (classactive) {
            $(this).removeClass("active");
            $(this).parents("ul").removeClass("active-grid");
        }
        else {
            $(this).addClass("active");
            //BuildSearchList(itemkey, itemvalue, child);
            $(this).parents("ul").addClass("active-grid");
        }
        SetSearchButtonUrl();
    });

    $(document).on('click', '.facets__section-list li label.facet-label', function () {
        $(this).parents(".facets__repeat-wrapper ").siblings(".facets__repeat-wrapper").children("ul").children("li").removeClass("active-grid");
        $(this).parents(".facets__repeat-wrapper").siblings(".facets__repeat-wrapper").children(".facets__section-list").children("li").children("ul").children("li").removeClass("active");
        $(this).parents(".facets__repeat-wrapper ").siblings(".facets__repeat-wrapper").removeClass("active-grid");
        $(this).parents(".facets__repeat-wrapper ").siblings(".facets__repeat-wrapper").children("li").children("ul").children("li").removeClass("active");

        $(this).siblings("li").removeClass("active");
        var classactive = $(this).hasClass("active");
        $(this).siblings("ul").children("li").removeClass("active");
        var itemkey = $(this).attr("rootAttr");
        var itemvalue = $(this).attr("attrValue");
        var isChild = $(this).attr("attrChild");
        var child = false;
        if (isChild != null) {
            child = true;
        }

        if (classactive) {
            $(this).removeClass("active");
            $(this).parents("li").removeClass("active-grid");
        }
        else {
            $(this).addClass("active");
            //BuildSearchList(itemkey, itemvalue, child);
            $(this).parents("li").addClass("active-grid");
        }
        SetSearchButtonUrl();
    });

    function SetSearchButtonUrl() {
        $('#SearchUrl').empty();
        for (var j = 0; j < searchterms.length; j++) {
            if (searchterms[j].value.indexOf(';') > -1 && itemkey == searchterms[j].value.split(";")[0]) {
                $('#SearchUrl').append(searchterms[j].value);
            } else {
                $('#SearchUrl').append("&amp;" + searchterms[j].key + "=" + searchterms[j].value);
            }
        }
    }

    //Function for Get array of search item
    function BuildSearchList(itemkey, itemvalue, ischildkey) {
        if (searchterms.length != 0) {
            var keyfound = false;
            for (var i = 0; i < searchterms.length; i++) {

                if (searchterms[i].key != null && searchterms[i].key == itemkey) {
                    searchterms[i] = { key: itemkey, value: itemvalue }
                    keyfound = true;
                }
            }
            if (!keyfound) {
                if (ischildkey) {
                    for (var j = 0; j < searchterms.length; j++) {
                        if (searchterms[j].value.indexOf(';') > -1) {
                            var newchildval = searchterms[j].value.split(";")[0];
                            if (searchterms[j].key != null && newchildval.toLowerCase() == itemkey) {
                                var newitemvalue = newchildval.toLowerCase() + ';' + itemvalue;
                                searchterms[j] = { key: searchterms[j].key, value: newitemvalue }
                                keyfound = true;
                            }
                        }
                        else {

                            if (searchterms[j].key != null && searchterms[j].value.replace(/[^A-Z0-9]/gi, '').toLowerCase() == itemkey) {
                                var newitemvalue = searchterms[j].value + ';' + itemvalue;
                                searchterms[j] = { key: searchterms[j].key, value: newitemvalue }
                                keyfound = true;

                            }
                        }
                    }
                }
                else {
                    searchterms.push({ key: itemkey, value: itemvalue });
                }
            }
        }
        else {
            searchterms.push({ key: itemkey, value: itemvalue });
        }
    }
});