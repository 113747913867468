(function () {
	'use strict';
  function AccordianTable (elemId) {
    var that = this;
    
    // bind main element to this object
    this.accElem = $('#' + elemId);
    if (this.accElem.length === 0) {
      console.log('bound accordian element not found')
      return;
    }

    // set props for dependent elements
    this.parentForm = this.accElem.closest('form');
    this.accExpander = this.accElem.find('.js-accordian-expander');
    this.multicheckBtn = this.accExpander.find('.account-email-multicheck');
    this.accBody = this.accElem.find('.js-accordian-body');
    this.checks = this.accBody.find('input[type=checkbox]');
    
    // methods
    this.trackInitialChecks = function() {
      that.checks.each(function () {
        $(this).attr('data-was-checked', this.checked);
      });
    }

    this.haveChecksChanged = function() {
      var haveChanged = false;
      that.checks.each(function () {
        if ($(this).attr('data-was-checked') !== this.checked.toString()) {
          haveChanged = true;
          return false;
        }
      });
      return haveChanged;
    }

    this.triggerAllChangedCheck = function () {
      that.parentForm.trigger('allchangedcheck');
    }

    this.initClickListeners = function () {
      that.multicheckBtn.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        
        // check / uncheck all checks
        that.toggleChecks();

        that.triggerAllChangedCheck();
        that.updateMulticheckBtnState();
      });

      that.checks.on('change', function () {
        that.triggerAllChangedCheck();
        that.updateMulticheckBtnState();
      });

      // toggle class to expand/collapse
      that.accExpander.on('click', function () {
        that.toggle();
      });
    }

    this.toggleChecks = function () {
      var isAllSelected = that.getIsAllSelected();
      that.checks.each(function () { this.checked = !isAllSelected });
    }

    this.updateMulticheckBtnState = function () {
      if (that.getIsAllSelected()) {
        that.multicheckBtn.children('.select-all').hide();
        that.multicheckBtn.children('.unselect-all').show();
      } else {
        that.multicheckBtn.children('.select-all').show();
        that.multicheckBtn.children('.unselect-all').hide();
      }
    }

    this.getIsAllSelected = function() {
      var isAllSelected = true;
      that.checks.each(function () {
        // if one isnt selected return false
        if (!this.checked) {
          isAllSelected = false;
          return false;
        }
      });
      return isAllSelected
    }

    this.setBodyHeight = function () {
      that.accElem.removeClass('ready');
      that.accBody.css('height', 'auto');
      that.accBody.css('height', that.accBody.height());
      that.accElem.addClass('ready');
    }

    this.toggle = function () {
      that.accElem.toggleClass('expanded');
    }

    // setup
    $(window).on('load', function () {
      that.initClickListeners();
      that.setBodyHeight();
      that.accElem.addClass('ready');
    });
    $(window).on('resize', function () {
      that.setBodyHeight();
    });
    this.updateMulticheckBtnState();
    this.trackInitialChecks();
  }

  var $accordians = $('.js-accordian'),
      $form,
      $submit,
      $notChangedModalOverlay = $('.modal-overlay'),
      $notChangedModalView = $('.modal-view'),
      setupNotChangedModal,
      showNotChangedModal,
      hideNotChangedModal,
      preventClicks;

  if ($accordians.length === 0) return;

  // define vars
  $form = $accordians.eq(0).closest('form');
  $submit = $form.find('button[type=submit]');

  // setup not changed modal
  setupNotChangedModal = function () {
    // click event for modal cross (X)
    $notChangedModalView.find('.close-modal').off('click').on('click', function () {
      hideNotChangedModal();
    });
    // click event for "do not save" (just nav away)
    $notChangedModalView.find('.cancel-modal').off('click').on('click', function (e) {
      window.location.href = $notChangedModalView.data('clickedurl');
    });
    // click event for "confirm save" (then navigate away)
    $notChangedModalView.find('.saveview').off('click').on('click', function () {
      $form.data('on-success', $notChangedModalView.data('clickedurl'));
      $submit.trigger('click');
      // removed the click blocker so if there's an error, the user isn't blocked but still see the feedback
      hideNotChangedModal();
    });
  }

  showNotChangedModal = function () {
    $notChangedModalOverlay.addClass('in');
    $notChangedModalView.show();
  }

  hideNotChangedModal = function () {
    $notChangedModalOverlay.removeClass('in');
    $notChangedModalView.hide();
  }

  // assign modal show to all anchors
  preventClicks = function (areChangesNotSaved) {
    if (areChangesNotSaved) {
      $('a').on('click.notchangedmodal', function (e) {
        e.preventDefault();
        // save url for reference after modal interaction
        $notChangedModalView.data('clickedurl', $(this).attr('href'));
        showNotChangedModal();
      });
    } else {
      $('a').off('click.notchangedmodal');
    }
  }

  // only enable submit if non of the accordion tables have changed
  $form.on('allchangedcheck', function () {
    var areAnyChecksChanged = false;
    // check each accordian
    $accordians.each(function () {
      var $accordian = $(this);
      if ($accordian.data('accobj').haveChecksChanged()) {
        areAnyChecksChanged = true;
        return false;
      }
    });
    // if any have changed enable sumbit btn
    if (areAnyChecksChanged) {
      $submit.removeAttr('disabled');
    } else {
      $submit.attr('disabled', 'disabled');
    }
    // enable not changed modal, preventing click away
    preventClicks(areAnyChecksChanged);
  });

  $submit.off('click.accordiantable').on('click.accordiantable', function () {
    preventClicks(false);
    $('.js-accordian').each(function () {
      var accobj = $(this).data('accobj');
      accobj.trackInitialChecks();
    })
    $form.trigger('allchangedcheck');
  })
  
  // init accordions
  $accordians.each(function (ind) {
    var $accordian = $(this),
        uniqueId = 'accordianTable' + (ind + 1);
    $accordian.attr('id', uniqueId);
    $accordian.data('accobj', new AccordianTable(uniqueId));
  });
  setupNotChangedModal();
})();