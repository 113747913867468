﻿import Cookies from '../jscookie';

$(function () {
    setTimezoneCookie();
});

function setTimezoneCookie() {

    var timezoneCookie = "ClientBrowser_TimezoneOffset";

    var timezoneCookieValue = Cookies.get(timezoneCookie);

    if (!timezoneCookieValue) {
            // create a new cookie 
        Cookies.set(timezoneCookie, new Date().getTimezoneOffset());
    }
    // if the current timezone and the one stored in cookie are different
    // then store the new timezone in the cookie and refresh the page.
    else {

        var storedOffset = parseInt(timezoneCookieValue);
        var currentOffset = new Date().getTimezoneOffset();

        // user may have changed the timezone
        if (storedOffset !== currentOffset) {
            Cookies.set(timezoneCookie, new Date().getTimezoneOffset());
        }
    }
}