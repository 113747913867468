$(function() {
    var $themeOmdia = $('body.theme-omdia').length,
        $longReadSection = $('#longReadJumpToSection a'),
        $downloads = $("#main-well .download-btn"),
        $dataInfo = {},
        assetTitle = $('.omd-banner h1').length > 0 ? $('.omd-banner h1').text() : $('.omd-banner h2').text();

    if ($themeOmdia) {
        $.each($longReadSection, function (prop, value) {
            $dataInfo.event_name = 'anchor_clicks';
            $dataInfo.click_through_source = $('#main-well').find("[id='" +  $(value)[0].hash.substring(1) + "']").text() || '';
            $dataInfo.click_destination = $(value).text() || '';
            $dataInfo.source_page_type = (window.analytics_data && window.analytics_data.page_type) || "";
            $(value).attr('data-info', JSON.stringify($dataInfo));
        });

        $.each($downloads, function (prop, value) {
            $dataInfo = {};
            var dnow = new Date().toISOString();
            $dataInfo.event_name = 'download_asset';
            $dataInfo.asset_file_type = $(value).text().split('|')[1] || '';
            $dataInfo.asset_name =  $(value).text().split('-')[0] || '';
            $dataInfo.asset_category = $(value).attr('data-category') || '';
            $dataInfo.product_codes = $('input[name=user_entitled_product_codes]').val();
            $dataInfo.article_number = window.analytics_data.article_number;
            $dataInfo.asset_size = $(value).attr('data-size');
            $dataInfo.asset_url = $(value).attr('href');
            $dataInfo.asset_created = dnow;
            $dataInfo.asset_modified = dnow;
            $(value).attr('data-info', JSON.stringify($dataInfo));
        });
        try {
          Stream;
          utag;
          analytics_data;
          if (Stream && utag && analytics_data) {
            $('[data-videoid]').each(function () {
              var $videoContainer = $(this),
                  videoid = $videoContainer.data('videoid'),
                  videoAnalysticsData = {
                    event_name: 'play_video',
                    asset_family_name: assetTitle,
                    video_id: videoid
                  }
              
              Stream($videoContainer.children('iframe')[0]).addEventListener('play', function () {
                utag.link($.extend(analytics_data, videoAnalysticsData));
              });
            });
          }
        } catch (error) {
          console.log(error);
        }
    }
});
