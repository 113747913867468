/*

opts.observe — Form element(s) to observe
opts.beforeRequest — Function to execute before making Ajax request
opts.successCallback — If Ajax request is successful, callback
opts.failureCallback — If Ajax request fails / returns false, callback

*/

function formController(opts) {

    var showSuccessMessage = function (form) {
        var $form = $(form),
            $success = $form.find('.js-form-success');
        
        if ($form.closest('.section-audience-campaign').length > 0) {
            $success.siblings().hide();
        }
        $success.show();
    };

    var hideSuccessMessage = function (form) {
        var $form = $(form),
            $success = $form.find('.js-form-success');
        
        $success.hide();
    };

    var showError = function (form, error) {
        if ($(form).find(error)) {
            $(form).find(error).show();
        }
    };

    var hideErrors = function (form) {
        $(form).find('.js-form-error').hide();
    };

    var $theamOmdia = $('body').hasClass('theme-omdia');

    (function init() {

        var form = opts.observe;

        if (!form) return false;

        var formSubmit = $(form).find('button[type=submit]');

        $(formSubmit).on('click', function (event) {

            // Some forms will require user confirmation before action is taken
            // Default to true (confirmed), set to false later if confirmation is
            // required and user cancels action
            var actionConfirmed = true;

            var currentForm;
            if (event.target.form) {
                currentForm = event.target.form;
            } else if($(event.target).closest('form').length) {
                currentForm = $(event.target).closest('form');
            } else {
                currentForm = $("#empty-form");
            }
            var captchaId = $(currentForm).find('.g-recaptcha:visible').attr('id');
            var sendRecaptcha = undefined;
            if (captchaId != undefined)
                sendRecaptcha = (captchaId === 'recaptchaAskTheAnalyst') ? recaptchaAskTheAnalyst : recaptchaEmail;
            // If In Future More Captcha will come in same page
		    /*
                var sendRecaptcha;
                if(captchaId === 'recaptchaAskTheAnalyst'){
                    sendRecaptcha = recaptchaAskTheAnalyst;
                }
                else if(captchaId === 'recaptchaEmail'){   
                     sendRecaptcha recaptchaEmail;
                }
                else{
                     sendRecaptcha otherIdval; 
                }
            */

            if ($(currentForm).data('force-confirm')) {
                actionConfirmed = window.confirm($(currentForm).data('force-confirm'));
            }

            if (actionConfirmed) {

                event.preventDefault(); // Prevent form submitting

                hideErrors(currentForm); // Reset any visible errors

                jQuery(".pop-out__input.pop-out__textarea").removeClass('error');

                if (opts.beforeRequest) {
                    opts.beforeRequest(currentForm);
                }

                // Prevent user from re-submitting form, unless explicitly allowed
                if (!$(currentForm).data('prevent-disabling')) {
                    $(formSubmit).attr('disabled', 'disabled');
                }

                // resend activation link for C+D vertical
                if ($("body").hasClass("theme-chemistanddruggist")) {
                    if ($(this).hasClass('resendLinkBtn')) {
                        $("#resendlink").val(true);
                    } else {
                        $("#resendlink").val(false);
                    }
                }

                var inputData = {};
                var IsValid = true;//Skip Validation if the form is not Update Contact Informatin Form
                if ($(currentForm).hasClass('form-update-account-contact')) {
                    IsValid = ValidateContactInforForm();
                }
                if (IsValid) {
                    $(currentForm).find('input, select, textarea').each(function () {

                        var value = '';
                        var field = $(this);

                        if (field.data('checkbox-type') === 'boolean') {
                            value = this.checked;

                            if (field.data('checkbox-boolean-type') === 'reverse') {
                                value = !value;
                            }
                        } else if (field.data('checkbox-type') === 'value') {
                            value = this.checked ? field.val() : undefined;
                        } else {
                            value = field.val();
                        }

                        if (value !== undefined) {
                            if (field.attr('name')) {
                                if (inputData[field.attr('name')] === undefined) {
                                    inputData[field.attr('name')] = value;
                                }
                                else if ($.isArray(inputData[field.attr('name')])) {
                                    inputData[field.attr('name')].push(value);
                                }
                                else {
                                    inputData[field.attr('name')] = [inputData[field.attr('name')]];
                                    inputData[field.attr('name')].push(value);
                                }
                            }
                        }
                    });


                    //// 25/10/2016 Commented captcha code to fix the js console error. Raju/Sonia will provide fix of this.
                    // add recaptcha if it exists in the form
                    var captchaResponse = undefined;
                    // add recaptcha if it exists in the form
                    if (sendRecaptcha !== undefined) {
                        captchaResponse = (grecaptcha == null) ? undefined : grecaptcha.getResponse(sendRecaptcha);
                    }

                    if (captchaResponse !== undefined)
                        inputData['RecaptchaResponse'] = captchaResponse;

                    if (!$(currentForm).data('on-submit')) {
                        console.warn('No submit link for form');
                    }
                    try {
                        for (var index in inputData) {
                            if (inputData[index] == "- Select One -") {
                                inputData[index] = "";
                            }
                        }
                    } catch (ex) { console.log(ex); }
                    // add spinner for #form-product-preferences-survey
                    if ($(currentForm).attr('id') == "form-product-preferences-survey") {
                        var surveyId = $(currentForm).find('input').val();
                        if (surveyId == "" || surveyId == null) {
                            $('.pro-pref-error').show();
                            return false;
                        }
                        window.INFORMA.Spinner.Show($('body'));
                    }
                    $.ajax({
                        url: $(currentForm).data('on-submit'),
                        type: $(currentForm).data('submit-type') || 'POST',
                        data: inputData,
                        context: this,
                        beforeSend: function () {
                            hideSuccessMessage(currentForm);
                        },
                        success: function (response) {
                            if (response.success) {

                                showSuccessMessage(currentForm);

                                // Passes the form response through with the "context"
                                // successCallback is ripe for refactoring, improving parameters
                                this.response = response;

                                // page reload on success of this form #form-product-preferences
                                if ($(currentForm).attr('id') == "form-product-preferences") {
                                    window.location.reload(true);
                                }

                                if ($(currentForm).attr('id') == "form-search-preferences" || $(currentForm).attr('id') == "form-search-preferences-none") {
                                    window.location.reload(true);
                                }

                                // //Specific to Sign In Form - Vertical Login
                                // if($(currentForm).hasClass('js-sign-in-submit') || $(currentForm).hasClass("form-registration")) {
                                // 	$('#hiddenforms_login form').each(function() {
                                // 		$(this).find('input[type="text"]').val(inputData["username"]);
                                // 		$(this)[0].submit();
                                // 	});
                                // }
                                // //Specific to Sign In Form - Vertical Login
                                if (opts.successCallback) {
                                    opts.successCallback(currentForm, this, event);
                                }

                                if ($(form).data('on-success')) {
                                    if ($(currentForm).hasClass('form-registration-optins') || $(currentForm).hasClass('form-registration-optins-v2')) {
                                        var today = new Date();
                                        var expiresDate = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate()).toUTCString();
                                        var cookie = document.cookie;
                                        var cookieArray = cookie.split(';');
                                        var url = $(currentForm).data('on-success');
                                        cookieArray.forEach(function (item) {
                                            item = item.trim();
                                            if (item.indexOf('isFreeTrial=') >= 0) {
                                                var oldValue = item.split('=')[1];
                                                if (oldValue.length > 0) {
                                                    if (oldValue.indexOf('|') >= 0) {
                                                        document.cookie = 'isFreeTrial=' + oldValue.substring(0, oldValue.indexOf('|') + 1) + url + ';path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
                                                    }
                                                    else {
                                                        document.cookie = 'isFreeTrial=' + oldValue + '|' + url + ';path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
                                                    }
                                                }
                                            }
                                        });
                                    }
                                    window.location.href = $(currentForm).data('on-success');
                                }
                                if (response.redirectRequired !== undefined && response.redirectRequired) {
                                    window.location.href = response.redirectUrl;
                                }
                                if (!$(currentForm).data('on-success') || $(currentForm).data('on-success').length <= 0) {
                                    if (getFormFreeTrialParameterByName("type", window.location.href) == "trial" || getFormFreeTrialParameterByName("istrial", window.location.href) == "true") {
                                        if ($('#Phone').val() != '' && $('#Phone').val() != 'undefined' && $('#JobTitle').val() != 'undefined' && $('#JobTitle').val() != '') {
                                            var returnUrl = getFormFreeTrialParameterByName("returnurl", window.location.href);
                                            window.location.href = "/trialprocessing?returnUrl=" + returnUrl + "";
                                        }
                                    }
                                }
                            }
                            else {
                                if (response.redirectUrl !== null && response.redirectUrl) {
                                    window.location.href = response.redirectUrl;
                                    return false;
                                }
                                if (response.reasons && response.reasons.length > 0) {
                                    for (var reason in response.reasons) {
                                        showError(form, '.js-form-error-' + response.reasons[reason]);
                                    }
                                } else if ( $theamOmdia && $(currentForm).hasClass('form-ask-the-analyst') && jQuery.trim($(".pop-out__input.pop-out__textarea").val()) === "") {
                                    showError(currentForm, '.ask-an-analyst-error');
                                    $('.pop-out__input.pop-out__textarea').addClass('error');
                                    return false;
                                } else {
                                    showError(currentForm, '.js-form-error-general');
                                }

                                if (opts.failureCallback) {
                                    opts.failureCallback(currentForm, response);
                                }
                                // add close spinner #form-product-preferences-survey
                                if ($(currentForm).attr('id') == "form-product-preferences-survey") {
                                    window.INFORMA.Spinner.Hide();
                                    $('.pro-pref-error').show();
                                    return false;
                                }
                            }

                            // add close spinner #form-product-preferences-survey
                            if ($(currentForm).attr('id') == "form-product-preferences-survey") {
                                window.location.reload();
                                window.INFORMA.Spinner.Hide();
                            }
                        },
                        error: function (response) {
                            // close spinner #form-product-preferences-survey
                            if ($(currentForm).attr('id') == "form-product-preferences-survey") {
                                window.INFORMA.Spinner.Hide();
                            }
                            showError(currentForm, '.js-form-error-general');

                            if (opts.failureCallback) {
                                opts.failureCallback(currentForm, response);
                            }
                        },
                        complete: function () {
                            if (!$(currentForm).data('prevent-disabling')) {
                                setTimeout((function () {
                                    $(formSubmit).removeAttr('disabled');
                                }), 250);
                            }

                            // reset captcha if available
                            grecaptcha.reset();
                        }

                    });
                } else {
                    //Enable Submit button if form data is not valid. It will be applicable only for form with class form-update-account-contact because for all other forms IsValid variable value is always true.
                    if (!$(currentForm).data('prevent-disabling')) {
                        $(formSubmit).removeAttr('disabled');
                    }
                }
            }
            return false;

        });
    })();
}
function getFormFreeTrialParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", 'i'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function ValidateContactInforForm() {
    var errorHtml = $('#errorMessage').html();
    var errors = 0;
    var result = false;
    var scrollTo = '';
    $('.required').each(function () {
        if ($(this).val() == '' || $(this).text().indexOf("- Select One -") >= 0) {
            $(this).parent().append(errorHtml);
            errors++;
            if (errors == 1) {
                scrollTo = $(this);
            }
        }
        else {
            $(this).parent().find('.js-form-error').remove();
        }

    });
    if (errors > 0) {
        window.scrollTo(0, scrollTo.offset().top - 30);
        result = false;
    }
    else {
        result = true;
    }
    return result;
}

export default formController;
