(function () {
    'use strict';
    var horizontalMenu = {
        $mainMenu: null,
        $toggleMenuSections: null,
        $nonSubmenuBtns: null,
        $menuSectionWrappers: null,
        isHorizontalMenuDesktopSet: false,
        initHorizontalMenuDesktop: function () {
            // handlers
            horizontalMenu.$toggleMenuSections.off('mouseenter.hm').on('mouseenter.hm', function () {
                horizontalMenu.$toggleMenuSections.removeClass('active');
                $(this).addClass('active');
            });
            horizontalMenu.$nonSubmenuBtns.off('mouseenter.hm').on('mouseenter.hm', function () {
                horizontalMenu.$toggleMenuSections.removeClass('active');
            });
            horizontalMenu.$mainMenu.off('mouseleave.hm').on('mouseleave.hm', function () {
                horizontalMenu.$toggleMenuSections.removeClass('active');
            });
            horizontalMenu.$menuSectionWrappers.off('focusin.hm').on('focusin.hm', function () {
                horizontalMenu.$toggleMenuSections.removeClass('active');
                $(this).siblings('.js-toggle-menu-section').addClass('active');
            })
            horizontalMenu.$menuSectionWrappers.off('focusout.hm').on('focusout.hm', function () {
                horizontalMenu.$toggleMenuSections.removeClass('active');
            })
        },
        destroyHorizontalMenuDesktop: function () {
            // handlers
            horizontalMenu.$toggleMenuSections.off('mouseenter.hm');
            horizontalMenu.$nonSubmenuBtns.off('mouseenter.hm');
            horizontalMenu.$mainMenu.off('mouseleave.hm');
            horizontalMenu.$menuSectionWrappers.off('focusin.hm focusout.hm');
        },
        horizontalMenuResizeHandler: function () {
            var windowWidth = $(window).width(),
                isDesktop = windowWidth > 1024;

            // detect if submenu exceeds bounds and align right if it does
            horizontalMenu.$menuSectionWrappers.each(function () {
                var projectedRight = $(this).closest('.main-menu__section')[0].getBoundingClientRect().x + this.getBoundingClientRect().width;
                $(this).css('right', projectedRight > $(window).width() ? 0 : '');
            });

            if (!horizontalMenu.isHorizontalMenuDesktopSet && isDesktop) {
                horizontalMenu.initHorizontalMenuDesktop();
                horizontalMenu.isHorizontalMenuDesktopSet = true;
            }

            if (horizontalMenu.isHorizontalMenuDesktopSet && !isDesktop) {
                horizontalMenu.$menuSectionWrappers.css('right', '');
                horizontalMenu.destroyHorizontalMenuDesktop();
                horizontalMenu.isHorizontalMenuDesktopSet = false;
            }
        },
        init: function () {
            // set props
            horizontalMenu.$mainMenu = $('.main-menu');
            horizontalMenu.$toggleMenuSections = horizontalMenu.$mainMenu.find('.js-toggle-menu-section');
            horizontalMenu.$nonSubmenuBtns = horizontalMenu.$mainMenu.find('.main-menu__home, .myviewLink, .main-menu__section-title:not(.js-toggle-menu-section)');
            horizontalMenu.$menuSectionWrappers = horizontalMenu.$mainMenu.find('.main-menu__section-wrapper');

            // set handler and init
            $(window).on('resize', horizontalMenu.horizontalMenuResizeHandler);
            horizontalMenu.horizontalMenuResizeHandler();

            horizontalMenu.$mainMenu.find('a:not([href])').click(function(e) {
                e.preventDefault();
            });
        }
    }
    if ($('body').hasClass('horizontal-menu')) {
        horizontalMenu.init();
    }
})();