function showAllTags (){
    $(".tags-container .showall").each(function() {
        var tagsWidth = 0;
        $(this).parents("h6").next("ul").children("li").each(function(){
            tagsWidth += $(this).width() + 10
        });
        if(tagsWidth < $(this).parents(".inf-container").width()) {
            $(this).hide();
        } else{
            $(this).show();
        }
        
        if($(window).width() < 768) {
            if(tagsWidth < $(this).parents(".inf-container").width() * 2) {
                $(this).hide();
            }else{
                $(this).show();
            }
        };

        $(this).click(function (e) {
            e.preventDefault();
            if (!$(this).parents("h6").next("ul").hasClass("visible")) {
                $(this).parents("h6").next("ul").addClass("visible");
                $(this).parents("h6").next("ul").css("maxHeight", "1000px");
				$(this).text("Collapse");
            } else {
                $(this).parents("h6").next("ul").removeClass("visible");
                $(this).parents("h6").next("ul").css("maxHeight", "40px");
                if ($(window).width() < 768) {
                    $(this).parents("h6").next("ul").css("maxHeight", "90px");
                }
				$(this).text("Show all");
            }
        });
    });
}
$(document).ready(function() {
    showAllTags();    
})
$(window).resize(function(){
    showAllTags();
});