$(function(){
  if ($('.case-studies-carousel') && $('.case-studies-carousel').length > 0) {
    $('.case-studies-carousel.owl-carousel').each(function(){
      var _this = this,
			itemPerSlide = $(_this).attr("data-slides-to-scroll") ? Number($(_this).attr("data-slides-to-scroll")) : 1;
			
		$(_this).owlCarousel({
        loop: false,
        autoplay: true,
        autoplayHoverPause: true,
        margin: 20,
        items: 1,
        nav: true,
        dots: false,
        slideBy: itemPerSlide,
        autoHeight:true,
        navText: [
          "<svg><use xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>", 
          "<svg><use xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>"
        ]
      });
    });
  }
});

