import { analyticsEvent } from '../controllers/analytics-controller';

var saveOriginFlag = false;
function setClsforFlw(t) {
  for (var i = 0; i < t.length; i++) {
    var tableFlwrow = $(t[i]).find('.followrow.disabled:eq(0)');
    tableFlwrow.addClass('frow');
  }
}

function createJSONData(alltables, UserPreferences, url) {
  for (var i = 0; i < alltables.length; i++) {
    var currenttabtrs = $(alltables[i]).find('tr'),
    alltdata = [];
    for (var j = 0; j < currenttabtrs.length; j++) {
      var hiddenInput = $(currenttabtrs[j]).find('input[type=hidden]'),
          eachrowAttr = hiddenInput.attr('data-row-topic'),
          channelId = hiddenInput.attr('data-row-item-id'),
		      isFollowing = hiddenInput.attr('data-row-status'),
		      dataResult = hiddenInput.attr('data-result'),
          datarowNo = $(currenttabtrs[j]).attr('data-row');

		  if(dataResult) {
			  UserPreferences.ResultsDisplayed = dataResult;
		  }
      if(channelId && datarowNo != 'fixed') {
        UserPreferences.PreferredChannels.push({ "ChannelId":channelId, "ChannelCode": eachrowAttr, "ChannelOrder": datarowNo,  "IsFollowing":isFollowing, Topics: null });
      }
    }   
  }
  
  sendHttpRequest(UserPreferences, null, url);
}
var saveData = false;
$('.dashboard-settings .saveview').click(function (e) {
  var alltables = $('.table'),
      UserPreferences = { "IsNewUser": false, "IsChannelLevel": false };
      UserPreferences.PreferredChannels = [];
 
  e.preventDefault();
  createJSONData(alltables, UserPreferences);
  saveData = true;
});


function setSessionPerPage(sentResultsDisplayed) {
	var selectedFacetsSess = sessionStorage.getItem("selectedFacets"),
		selectedFacets = new URLSearchParams(selectedFacetsSess);
	if (selectedFacets.size > 0 && selectedFacets.has('perPage')) {
		selectedFacets.set('perPage', parseInt(sentResultsDisplayed));
		sessionStorage.setItem("selectedFacets", selectedFacets.toString());
	}
}

function sendHttpRequest(UserPreferences, setFlag, redirectUrl) {
  window.submittedUserPrefs = UserPreferences;
  $.ajax({
    url: '/Account/api/PersonalizeUserPreferencesApi/Update/',
    data: { 'UserPreferences': JSON.stringify(window.submittedUserPrefs) },
    dataType: 'json',
    type: 'POST',
    success: function success(data) {
      if (data && data.success) {
        setSessionPerPage(window.submittedUserPrefs.ResultsDisplayed);
        SetIsFreeTrialCookieForReg();
        hideModal();
        $('.alert-success p').html(data.reason);
        $('.alert-success').addClass('show');
        if ($('.alert-success').length > 0) {
          $(window).scrollTop($('.informa-ribbon').offset().top + $('.informa-ribbon').height());
        }
        setTimeout(function(){
          window.location.href = '/my-dashboard';
        }, 3000)
      }
      else {
        if (data.redirectUrl !== null && data.redirectUrl) {
          window.location.href = data.redirectUrl;
          return false;
        }
        if (redirectUrl !== 'href' && redirectUrl !== 'name') {
          window.location.href = redirectUrl;
        }
        if (setFlag === 'register') {
          $('.alert-error.register-error p').html(data.reason);
          $('.alert-error.register-error').addClass('show');
        }
        else {
          $('.alert-error.myview-error p').html(data.reason);
          $('.alert-error.myview-error').addClass('show');
        }
      }
    },
    error: function (err) {
      if (err && !err.success) {
        if (setFlag === 'register') {
          $('.alert-error.register-error p').html(data.reason);
          $('.alert-error.register-error').addClass('show');
        }
        else {
          $('.alert-error.myview-error p').html(data.reason);
          $('.alert-error.myview-error').addClass('show');
        }
      }
    }
  });
}

function setDataRow(allpublications) {
  for (var k = 0; k < allpublications.length; k++) {
    var tbody = $(allpublications[k]).find('tbody'), newtrs = tbody.find('tr');
    newtrs.removeAttr('data-row');
    for (var v = 0; v < newtrs.length; v++) {
      $(newtrs[v]).attr('data-row', v + 1);
    }
  }
}

function showModal() {
  $('.modal-overlay').addClass('in');
  $('.modal-view').show();
}
function hideModal() {
  $('.modal-overlay').removeClass('in');
  $('.modal-view').hide();
}

function sendRegisterData(alltrs, UserPreferences, redirectUrl) {
  for (var i = 0; i < alltrs.length; i++) {
    var eachrowAttr = $(alltrs[i]).find('input[type=hidden]').attr('data-row-topic'),
      channelId = $(alltrs[i]).find('input[type=hidden]').attr('data-row-item-id'),
      secondtd = $(alltrs[i]).find('td.wd-25 span').html(),
      channelOrder = $(alltrs[i]).attr('data-row'),
      followStatus = (secondtd === $('#followingButtonText').val()) ? true : false;

    UserPreferences.PreferredChannels.push({ "ChannelCode": eachrowAttr, "ChannelOrder": channelOrder, "IsFollowing": followStatus, "Topics": [] });
  }
  sendHttpRequest(UserPreferences, 'register', redirectUrl);
}

function sort_table(tbody, col, asc, sortstatus) {
  var rows = [];
  if (tbody[0] && tbody[0].rows) {
    var allrows = tbody[0].rows;
  } else {
    return;
  }
  if (sortstatus === 'followingBtn') {
    for (var j = 0; j < allrows.length; j++) {
      if (allrows[j].className === 'followrow disabled' || allrows[j].className === 'followrow disabled frow') {
        rows.push(allrows[j]);
      }
    }
  } else if (sortstatus === 'followingrow') {
    for (var j = 0; j < allrows.length; j++) {
      if (allrows[j].className === 'followingrow') {
        rows.push(allrows[j]);
      }
    }
  } else if (sortstatus === 'followrow') {
    for (var j = 0; j < allrows.length; j++) {
      if (allrows[j].className === 'followrow disabled' || allrows[j].className === 'followrow disabled frow') {
        rows.push(allrows[j]);
      }
    }
  }

  var rlen = rows.length,
      arr = new Array(),
      i, j, cells, clen,
      topicName = [];
      
  for (i = 0; i < rlen; i++) {
    cells = rows[i].cells;
    clen = cells.length;
    arr[i] = new Array();
    for (j = 0; j < clen; j++) {
      if (j == 0) {
        topicName.push($.trim(cells[0].innerHTML));
        arr[i][j] = $.trim(cells[0].innerHTML.toLowerCase());
      } else {
        arr[i][j] = $.trim(cells[j].innerHTML);
      }
    }
  }
  // sort the array by the specified column number (col) and order (asc)
  arr.sort(function (a, b) {
    return a[col] === b[col] ? 0 : a[col] > b[col] ? asc : -1 * asc;
  });

  for (var i = 0; i < arr.length; i++) {
    for (var j = 0; j < topicName.length; j++) {
      if ($.trim(arr[i][0]) === $.trim(topicName[j].toLowerCase())) {
        arr[i][0] = topicName[j];
      }
    }
  }

  // replace existing rows with new rows created from the sorted array
  for (i = 0; i < rlen; i++) {
    rows[i].innerHTML = "<td class='wd-55'>" + arr[i][0] + "</td><td class='wd-25'>" + arr[i][1] + "</td>";
  }
}

function SetIsFreeTrialCookieForReg() {
  var cookies = document.cookie;
  var cookieArray = cookies.split(';');
  cookieArray.forEach(function (item) {
    item = item.trim();
    if (item.indexOf('isFreeTrial=') >= 0) {
      var today = new Date();
      var expiresDate = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate()).toUTCString();
      document.cookie = 'isFreeTrial=False;path=/;secure;expires=' + expiresDate + ';domain=' + window.location.hostname + ';';
    }
  });
}

$(function () {
  var tablesWrapper = $('.publicationPan'),
      tables = tablesWrapper.find('table');

  setClsforFlw(tables);
  tablesWrapper.on('click', '.theme-omdia .followrow .followBtn', function (e) {
    var $this = $(this),
        followrow = $this.closest('.followrow'),
        table = $this.closest('.table'),
        trs = $this.closest('tbody').find('tr');

    followrow.find("input").attr("data-row-status", true);
    followrow.attr('draggable', true);
    followrow.addClass('followingrow').removeClass('followrow disabled frow');
    $this.addClass('followingBtn active').removeClass('followBtn');
    setClsforFlw(table);
    if (trs.hasClass('followingrow')) {
      $('#validatePriority').val(true);
    }
    followrow.clone().appendTo($(this).closest('table').find('tbody'));
    followrow.remove();
  });

  tablesWrapper.on('click', '.theme-omdia .followingrow .followingBtn', function (e) {
    var $this = $(this),
        table = $this.closest('table'),
        followingrow = $this.closest('.followingrow'),
        tbody = $this.closest('tbody'),
        trs = $this.closest('tbody').find('tr'),
        disabledtrs = $this.closest('table').find('.followrow.disabled');

    followingrow.find("input").attr("data-row-status", false);
	  followingrow.attr('draggable', false);
    followingrow.addClass('followrow disabled').removeClass('followingrow');
    $this.addClass('followBtn').removeClass('followingBtn active');
    followingrow.clone().appendTo($this.closest('table').find('tfoot'));
    followingrow.remove();
    $('#validatePreference').val(1);
    sort_table(tbody, 0, 1, 'followingBtn');
    $('#validateMyViewPriority').val(true);

    if (trs.length === disabledtrs.length + 1) {
      table.find('.firstrow .lableStatus').val('followlbl');
    }
  });

  if (tablesWrapper && tablesWrapper.length) {
    $('.publicationPan').each(function (ele, item) {
      $(item).dragswap({
        element: '.table tbody tr',
        dropAnimation: true
      });
    });
  }
});

var selectBox = $("#myDashboardEdit .select-box");
selectBox.each(function(index, item){
  $(item).click(function(){
    $(".select-option").toggle();
  });
  $(item).find(".select-option li").click(function(e){
    e.preventDefault();
    $(item).find(".select-option li").removeClass("is-selected");
    $(this).addClass("is-selected");
    console.log($(this).attr("data-select-value"));
    $("#myDashboardEdit [data-select-id=select-value]").attr("data-result", $(this).attr("data-select-value"));
    $(item).find(".select-value > span").text($(this).attr("data-select-value"));
  });
});

$(document).ready(function() {
  $(selectBox).find(".select-value > span").text($("#myDashboardEdit [data-select-id=select-value]").attr("data-result"));
  $(selectBox).find(".select-option li").each(function(index, item){
    $(item).removeClass("is-selected");
    if($(item).attr("data-select-value") === $("#myDashboardEdit [data-select-id=select-value]").attr("data-result")){
      $(item).addClass("is-selected");
    }
  });

  var layoutChanged = false;
  $("#myDashboardEdit").on('DOMSubtreeModified', (e) => {
    layoutChanged = true;
  })

  $('a').click(function (e) {
    if(layoutChanged && !saveData && !$(this).hasClass('gotodashboard')){
      e.preventDefault();
      showModal();
    }
  });

  $('.dashboard-settings .close-modal').click(function () {
    hideModal();
  });
  
});
