$(function() {
        var btnBottom = document.getElementById("contactInfo"),
            $body = $('body'), 
            $theamOmdia = $body.hasClass('theme-omdia');
        
        function syncClick(event) {
            event.preventDefault();
            if ($theamOmdia && btnBottom !== undefined) {
                btnBottom.click();
            }
        }
       
    });

