$(document).ready(function () {
    $(".sub-menu__wrapper").each(function () {
        var bgColor = $(this).data("background-color");
        if (bgColor) {
            $(this).css({
                "background-color": bgColor,
            });
            $(this).find(".submenu").css({
                "background-color": bgColor,
            });
            $(this).find(".sub-menu__navigation").css({
                "background-color": bgColor,
            });
        }
    });
});