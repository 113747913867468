// * * *
//  ANALYTICS CONTROLLER
//  For ease-of-use, better DRY, better prevention of JS errors when ads are blocked
// * * *

function trackBusinessEvt(dataObj) {
  if (window.IIRISTracker) {
    pushBusinessEvt(dataObj);
  }

  window.addEventListener("trackerLoaded", function (evt) {
    if (window.IIRISTracker) {
      pushBusinessEvt(dataObj);
    }
  });
}

function pushBusinessEvt(dataObj) {
  var dnow = new Date().toISOString();
  var assetPathArr = dataObj.asset_url ? dataObj.asset_url.split("/") : [""];
  var filename = assetPathArr[assetPathArr.length - 1];
  var obj1 = {
    entityId: dataObj.asset_name || "",
    transactionId: create_UUID(),
    status: "success",
    type: "download",
    ts: dnow,
  };
  var obj2 = {
    id: dataObj.asset_name || "",
    name: filename,
    type: dataObj.asset_file_type || "",
    size: dataObj.asset_size || "",
    url: dataObj.asset_url || "",
    created: dataObj.asset_created || "",
    lastUpdated: dataObj.asset_modified || "",
    tags: dataObj.article_number || "",
  };
  //   console.log("obj1", obj1);
  //   console.log("obj2", obj2);
  window.IIRISTracker.trackBusinessEvt(obj1, obj2);
}

function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

function analyticsEvent(dataObj) {
  if (typeof utag !== "undefined") {
    utag.link(dataObj);
  }
  if (dataObj.event_name === "download_asset") {
    trackBusinessEvt(dataObj);
  }
};

export { analyticsEvent };
