$(function(){
	if ($('.video-carousel') && $('.video-carousel').length > 0) {
    $('.video-carousel.owl-carousel').each(function(){
      var _this = this,
			    itemPerSlide = $(_this).attr("data-slides-to-scroll") ? Number($(_this).attr("data-slides-to-scroll")) : 1;
			
		$(_this).owlCarousel({
        loop: false,
        autoplay: true,
        autoplayHoverPause: true,
        margin: 20,
        items: 3,
        nav: true,
        dots: false,
        slideBy: 3,
        navText: [
          "<svg><use xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>", 
          "<svg><use xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>"
        ],
        responsive: {
          0: {
            items: 1,
            slideBy: 1
          },
          768: {
            items: 2,
            slideBy: 1
          },
          1280: {
            items: 3,
            slideBy: itemPerSlide
          }
        }
      });
    });

		// on each video card replace video url on relative modal
		$(".video-carousel .video-card").each(function(){
      var _this = this,
			    cardHref = $(_this).attr("data-video-url"),
          videoUrl = (cardHref != "" && cardHref != null) ? $(_this).attr("data-video-url") + "&autoplay=1" : "";

			$(_this).find(".video-card-top, .video-card-content .cta").click(function (e) {
        e.preventDefault();
        if (videoUrl != "") {
          $("#custom-popup-video iframe").attr("src", videoUrl);
          $("#custom-popup-video").modal("show");
        }  
			});
		});

		$("#custom-popup-video").on("click", ".close", function(){
			$("#custom-popup-video iframe").attr("src", "");
		});
  }
});

