function hashParams (){
    var hashValue = window.location.hash,
        hashStr = hashValue ? hashValue.split("?")[1] : "",
        pieces = hashStr.split("&"),
        parts,
        i;

    var generateHashParam = function(){
        hashValue = Object.keys(this.data).map(function (key) {
            return key + '=' + this.data[key];
        }).join('&');
        window.location.hash = "?" + hashValue;
    }
    
    this.data = function(){
        // process each query pair
        for (i = 0; i < pieces.length; i++) {
            parts = pieces[i].split("=");
            if (parts.length < 2) {
                parts.push("");
            }
            paramObj[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        }
        return paramObj
    }

    this.valueIsTrue = function(){
        return hashValue && hashValue.split("?")[1] != "" ? true : false;
    }
	
	this.updateParam = function(key, value) {
		if (hashValue == "") {
			hashValue += key + "=" + value;
		} else {
			this.data[key] = value;
		}
        generateHashParam();
	};

    this.deleteParam = function(key) {
        delete this.data[key];
        generateHashParam();
    }
}