$(function() {
    if( $(document.body).hasClass("theme-omdia") && 
        $('.omd-col-main') && $('.omd-col-main').length == 0){
        return; //Exit
    }
    //Execute only in case of Asset Family page in Omdia 
    //Find UL elements - if UL element 
    //IF UL element is first child of li then add class to LI
    //LI class will nullify the list icon style
    var parents = $('.article-list--ul').parents();
    for (var i=0; i< parents.length; i++)  {
        if (parents[i].tagName == 'LI' &&
            parents[i].children[0].tagName == 'UL')  {
                parents[i].classList.add("nested-ul");
        }
      }
});