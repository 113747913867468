$(function(){
    var letsConnectServiceBtn = $("[data-target='#letsConnectService']"),
        letsConnectServicePopup = $('#letsConnectService');
	if (letsConnectServiceBtn.length) {
        var iframeUrl, modalHeading, modalSubHeading, iframeHeight;
        letsConnectServiceBtn.click(function () {
            iframeUrl = $(this).attr("href"),
            modalHeading = $(this).attr("data-modal-heading"),
            modalSubHeading = $(this).attr("data-modal-subheading"),
            iframeHeight = $(this).attr("data-iframe-height");
			$(letsConnectServicePopup).find("iframe").attr("src", iframeUrl);
            $(letsConnectServicePopup).find("iframe").attr("height", iframeHeight);
            
            $(letsConnectServicePopup).find(".custom-popup-header > h4").html(modalHeading);
            $(letsConnectServicePopup).find(".custom-popup-header > p").html(modalSubHeading);
        });

		$(letsConnectServicePopup).on('click', ".close", function (e) {
			$(letsConnectServicePopup).find("iframe").attr("src", "");
            $(letsConnectServicePopup).find(".custom-popup-header > h4").html("");
            $(letsConnectServicePopup).find(".custom-popup-header > p").html("");
            $(letsConnectServicePopup).find("iframe").attr("height", "");
		});
    }
});