(function () {
  "use strict";
  var updateLinkUTMTracking = function () {
    var utmQueryStr = $(document).data("utmQueryStr");
    $("a[href^='http'], a[href^='//']").each(function () {
      var href, hrefPartsArr, hrefQueryStr, newHrefQueryStr, appendHref;

      // run once check
      if ($(this).hasClass("link-utm-updated")) return true;

      // set vars
      href = this.href.replace(/\/$/, "").replace(/&amp;/g, "&");
      hrefPartsArr = href.split("?");
      hrefQueryStr = hrefPartsArr.length > 1 ? hrefPartsArr[1] : "";

      // loop url params and filter them out if they contain '_utm'
      if (hrefQueryStr.indexOf("utm_") > -1) {
        newHrefQueryStr = hrefQueryStr
          .split("&")
          .filter(function (urlParam) {
            var urlQueryParamKey = urlParam.split("=")[0].toLowerCase();
            return urlQueryParamKey.indexOf("utm_") === -1;
          })
          .join("&");
      } else {
        newHrefQueryStr = hrefQueryStr;
      }

      // adds & if existing url already has queries, else ?
      appendHref =
        "?" +
        (newHrefQueryStr.length > 0 ? newHrefQueryStr + "&" : "") +
        utmQueryStr;

      this.href = hrefPartsArr[0] + appendHref;

      $(this).addClass("link-utm-updated");
    });
  };

  var setUTMQueryString = function () {
    var hdnUtmSource = $("#UtmParameters").val(),
      utm,
      utmQueryKeys;

    // sets utmQueryStr as empty so early returns will cancel
    $(document).data("utmQueryStr", "");

    // early return where field isn't present
    if (!hdnUtmSource) {
      console.warn("UTM parameters field expected");
      return;
    }

    // convert string to js object
    // utm = JSON.parse(hdnUtmSource);

    // early return where utm_source is not present or it's value = direct
    // if (!utm.utm_source) {
    //   console.warn("utm_source not detected");
    //   return;
    // }
    // if (utm.utm_source.toLowerCase() === "direct") {
    //   console.warn("utm_source=direct detected");
    //   return;
    // }

    // filter utm kv pairs and store
    // utmQueryKeys = Object.keys(utm).filter(function (key) {
    //   var val = utm[key];
    //   return key.indexOf("utm_") > -1 && val;
    // });

    // early return where at least 1 UTM parameter is not present
    if (hdnUtmSource.length === 0) {
      console.warn("no valid UTM parameters detected");
      return;
    }

    // construct query string from utm field vals
    $(document).data("utmQueryStr", hdnUtmSource);
  };

  var startUTMObserver = function () {
    window.UTMObserver = new MutationObserver(updateLinkUTMTracking);
    window.UTMObserver.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  };

  var initLinkUTMTracking = function () {
    var utmQueryStr = $(document).data("utmQueryStr");

    // set utm string first time and rerun func
    if (utmQueryStr === undefined) {
      setUTMQueryString();
      initLinkUTMTracking();
      return;
    }

    // continue if utm string is set
    if (!utmQueryStr || utmQueryStr.length === 0) return;
    startUTMObserver();
    updateLinkUTMTracking();
  };

  // run at start
  $(window).on('load', initLinkUTMTracking);
})();
