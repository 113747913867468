﻿$(function () {
  var isThemeOmdia = $('body').hasClass('theme-omdia');
  const powerBiEmbeds = document.getElementsByClassName('js-powerbi-embed');
  if (powerBiEmbeds != null && powerBiEmbeds.length > 0) {
    for (let i = 0; i < powerBiEmbeds.length; i++) {
      const heightAttribute = powerBiEmbeds[i].attributes['data-powerbi-height'];
      const widthAttribute = powerBiEmbeds[i].attributes['data-powerbi-width'];
      const typeAttribute = powerBiEmbeds[i].attributes['data-powerbi-type'];
      const tokenAttribute = powerBiEmbeds[i].attributes['data-powerbi-token'];
      const embedUrlAttribute = powerBiEmbeds[i].attributes['data-powerbi-embedurl'];
      const idAttribute = powerBiEmbeds[i].attributes['data-powerbi-id'];

      // Get models. models contains enums that can be used.
      var models = window['powerbi-client'].models;

      // Embed configuration used to describe the what and how to embed.
      // This object is used when calling powerbi.embed.
      // This also includes settings and options such as filters.
      // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
      var config = {
        type: typeAttribute.value,
        tokenType: models.TokenType.Embed,
        accessToken: tokenAttribute.value,
        embedUrl: embedUrlAttribute.value,
        id: idAttribute.value
      };

      if (isThemeOmdia) {
        $(powerBiEmbeds[i]).closest('.inf-container').addClass('powerbi-dashboard-container');

        powerBiEmbeds[i].style.width = '100%';
  
        if (widthAttribute && widthAttribute.value && heightAttribute && heightAttribute.value) {
          powerBiEmbeds[i].style.height = $(powerBiEmbeds[i]).width() * (heightAttribute.value / widthAttribute.value) + 'px';
        }
      } else {
        if (heightAttribute && heightAttribute.value) {
            powerBiEmbeds[i].style.height = heightAttribute.value + 'px';
        }

        if (widthAttribute && widthAttribute.value) {
            powerBiEmbeds[i].style.width = widthAttribute.value + 'px';
        }
      }

      // Embed the dashboard and display it within the div container. The embed returns an object, for example:
      // var dashboard = powerbi.embed(document.querySelector('#' + powerBiEmbeds[i].id), config);
      powerbi.embed(document.querySelector('#' + powerBiEmbeds[i].id), config);
    }
  }
  
  if (isThemeOmdia) {
    $(window).on('resize', function () {
      const powerBiEmbeds = document.getElementsByClassName('js-powerbi-embed');
      if (powerBiEmbeds != null && powerBiEmbeds.length > 0) {
        for (let i = 0; i < powerBiEmbeds.length; i++) {
          const heightAttribute = powerBiEmbeds[i].attributes['data-powerbi-height'];
          const widthAttribute = powerBiEmbeds[i].attributes['data-powerbi-width'];

          powerBiEmbeds[i].style.width = '100%';

          if (widthAttribute && widthAttribute.value && heightAttribute && heightAttribute.value) {
            powerBiEmbeds[i].style.height = $(powerBiEmbeds[i]).width() * (heightAttribute.value / widthAttribute.value) + 'px';
          }
        }
      }
    });
  }
});