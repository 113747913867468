$(function(){
	// custom select
	$(".custom-select").each(function(item){
		var select = $(this),
			selectField = select.find(".custom-select-value"),
			selectListBox = selectField.next('div'),
			selectList = select.find(".custom-select-list"),
			initValue = selectList.children().first().text();			

		selectField.val(initValue);
		selectList.children().first().addClass("active");
		selectField.click(function(){
			$(this).closest(select).toggleClass("open");
			selectListBox.toggle();
		});

		selectList.children().each(function(item){
			var listItem = $(this),
				listVal = listItem.text();
				
			listItem.click(function(e){
				e.preventDefault();
				$(this).addClass("active").siblings().removeClass("active");
				selectField.val(listVal);
				select.closest(select).removeClass("open");
				selectListBox.hide();
			})
		});

		$(document).on('click', function(e){
			if($(e.target).closest(select).length == 0 || ($(e.target).closest(selectField).length == 0 && !selectField.is(":visible"))) {
				select.closest(select).removeClass("open");
				selectListBox.hide();
			}
		});		
	});
});