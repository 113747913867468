$(function() {
    var $body = $('body'),
        $overlay = "<div class='analyst-overlay'></div>";

        $body.append($overlay);
        
        $(".action-flag.pop-out__trigger.js-pop-out-trigger[data-pop-out-type='ask-the-analyst'], .dismiss-button.js-dismiss-ask-the-analyst").on('click', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $('.ask-an-analyst, .pop-out.js-pop-out__ask-the-analyst').toggleClass('show').toggleClass('is-active');
            $('.analyst-overlay').removeClass('active');
            $('.js-ask-the-analyst-form-wrapper').show();
            $('.js-ask-the-analyst-success').hide();
            $('.button--filled.click-utag').attr('disabled', 'disabled');
            $('input[type=text]:not([readonly])').val('');
            $(".pop-out__input.pop-out__textarea").val('');
            window.controlPopOuts.closePopOuts();
        });
});

var $profileAlert = $("#profile-alert-msg"),
  $analystModals = $("[id^=follow-analyst]"),
  toggleFollowBtnState = function ($followBtn) {
    var isFollowing = $followBtn.hasClass("analyst-following"),
      $svgUse = $followBtn.find("use"),
      $textContainer = $followBtn.children("span");
    if (isFollowing) {
      $svgUse.attr("xlink:href", "/dist/img/svg-sprite.svg#follow-button");
      $textContainer.text("Follow Analyst");
      $followBtn.removeClass("analyst-following");
    } else {
      $svgUse.attr("xlink:href", "/dist/img/svg-sprite.svg#checkmark");
      $textContainer.text("Following");
      $followBtn.addClass("analyst-following");
    }
  },
  toggleAlertState = function (stateStr, isReload) {
    var $activeStateEl = isReload ? $profileAlert : $profileAlert.find("[data-status=" + stateStr + "]");
    $activeStateEl.addClass("is-active");
    if (!isReload)
      $activeStateEl.siblings().removeClass("is-active");
    setTimeout(function () {
      $activeStateEl.removeClass("is-active");
      if (isReload) location.reload(true);
    }, 3500);
  };

$analystModals.each(function () {
  var $analystModal = $(this),
    isModalDynamic = $analystModal.find(".modal-body").length > 1;

  $analystModal.on("click", function (e) {
    if (e.target !== e.currentTarget) return;
    $(this).modal("hide");
  });

  // store isdynamic for use in submit/modal open listeners
  $analystModal.data("is-dynamic", isModalDynamic);
});

window.addEventListener("show.bs.modal", function (e) {
  var $currBtn = $(e.relatedTarget),
    analystInfoData = $currBtn.data("info"),
    $currModal = $(e.target),
    $modalSubmitBtn = $currModal.find("[id=applyFollowAnalyst]"),
    $analystName = $currModal.find(".js-analyst-name"),
    isFollowing = $currBtn.hasClass("analyst-following"),
    isModalDynamic = $currModal.data("is-dynamic") || false;

  // customise modal text
  if ($analystName.length > 0)
    $analystName.html(
      analystInfoData.FirstName + " " + analystInfoData.LastName
    );

  // assume dynamic if more than 1 body
  if (isModalDynamic) {
    // apply attrs to pass on to eloqua
    $modalSubmitBtn.attr({
      "data-authorId": $currBtn.attr("data-authorId") || "",
      "data-authorTypeId": $currBtn.attr("data-authorTypeId") || "",
      "data-salesforceId": $currBtn.attr("data-salesforceId") || "",
      "data-info": JSON.stringify(analystInfoData),
    });
    // modal state
    $currModal
      .find("[data-status=" + (isFollowing ? "un" : "") + "following]")
      .show()
      .siblings()
      .hide();
  }

  // store button reference for future access
  $currModal.data("follow-btn", $currBtn);
});

$("[id^=follow-analyst]").on("click", function (e) {
  if (e.target !== e.currentTarget) return;
  $(this).modal("hide");
});

$("[id=applyFollowAnalyst]").on("click", function (event) {
  event.preventDefault();
  var $this = $(this),
    $currModal = $this.closest("[id^=follow-analyst]"),
    isModalDynamic = $currModal.data("is-dynamic") || false,
    $followBtn = $currModal.data("follow-btn"),
	  isFollowing = $followBtn.hasClass("analyst-following"),
    dataInfo = "",
    apiEndpoint = "",
    dataInfoJson = "",
    salesforceId = $this.attr("data-salesforceId"),
    authorId = $this.attr("data-authorId"),
    authorTypeId = $this.attr("data-authorTypeId");

  if (salesforceId == "" || salesforceId == undefined) {
    dataInfo = $this.attr("data-info");
    apiEndpoint = "/Account/api/SavedDocumentApi/SaveItem/";
    dataInfoJson = JSON.parse(dataInfo);
    dataInfoJson.AlertFreq =
      $("#email-alert").prop("checked") == true ? "WEEKLY" : "NONE";
  } else {
    apiEndpoint = "/Account/api/SavedDocumentApi/RemoveItem/";
  }

  $currModal.modal("hide");
  if (isModalDynamic) toggleFollowBtnState($followBtn);
  $.ajax({
    url: apiEndpoint,
    type: "POST",
    dataType: "json",
    data: {
      DocumentID: authorId,
      DocumentType: "Author",
      DocumentInfo: JSON.stringify(dataInfoJson),
      DocumentTypeId: authorTypeId,
      SalesforceID: salesforceId,
      SavedDate: new Date().toUTCString(),
    },
    success: function success(result) {
      var alertStatus = "success-" + (isFollowing ? "un" : "") + "following";
      toggleAlertState(alertStatus, !isModalDynamic);
    },
    error: function error(jqXHR, textStatus, errorThrown) {
      if (isModalDynamic) toggleFollowBtnState($followBtn);
      toggleAlertState("error", !isModalDynamic);
    },
  });
});