var SearchScript = function() {
	/* Toggle search tips visibility */
	$('.search-bar__tips').each(function(index, item){
		$(item).find(".js-toggle-search-tips").on('click', function toggleTips() {
			$(item).toggleClass('open');			
			if ($("body").hasClass("theme-omdia")) {
				if($(item).hasClass('open') && $('.search-results').length > 0) {
					$('.search-results').css({
						minHeight: $('.search-bar__tips-panel').height() + 100
					})
				}else{
					$('.search-results').css({
						minHeight: 'initial'
					})
				}
			}
		});
	})
	if ($("body").hasClass("theme-omdia")) {
		document.body.addEventListener("click", function(e){
			var searchTipsBtn = document.querySelector(".search-bar__tips");
			var searchPanel = document.querySelector(".search-bar__tips-panel");
			if(searchTipsBtn && !searchTipsBtn.contains(e.target) && !searchPanel.contains(e.target)) {
				searchTipsBtn.classList.remove("open");
			}
		});
	}
}();
